/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import React, {memo, useEffect, useState} from 'react';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useParams} from "react-router";
import {AppDatastoreApi} from "../../api/AppDatastoreApi";
import {toast} from "react-toastify";
import {datastoresPath, viewDatastorePath} from "../../routes";
import {useLoading} from "../../layouts/Main";
import {EpisodeDirectory, EpisodeMapping} from "../../api/interfaces";
import {EpisodeMappingForm} from "./EpisodeMappingForm";
import {AppEpisodeApi} from "../../api/AppEpisodeApi";

/**
 * Component to create a new episode mapping. Fetches episode directories
 * and provides a form for creating a new episode mapping entry.
 */
function AddEpisodeMapping() {

    const {datastoreId} = useParams()
    const navigate = useNavigate()
    const {setLoading} = useLoading();

    const [episodeDirectories, setEpisodeDirectories] = useState<EpisodeDirectory[]>([]);
    const [selectedEpisodeDirectory, setSelectedEpisodeDirectory] = useState<EpisodeDirectory>({
        path: '',
        dsEntryId: '',
        episodeCode: ''
    } as EpisodeDirectory);
    const [episodeMapping, setEpisodeMapping] = useState<EpisodeMapping>({
        dsId: datastoreId ? datastoreId : '',
    } as EpisodeMapping);


    useEffect(() => {
        if (!datastoreId) {
            toast.error('Datastore ID is undefined');
            navigate(datastoresPath);
            return;
        }
        setLoading(true);
        AppEpisodeApi.getEpisodeDirectories(datastoreId)
            .then(setEpisodeDirectories)
            .catch(error => toast.error('Episode directories could not be retrieved. Reason: ' + error.message))
            .finally(() => setLoading(false))
    }, [datastoreId]);

    const handleSubmitCreate = async (event: React.FormEvent<HTMLFormElement>, episodeMapping: EpisodeMapping) => {
        event.preventDefault(); // prevent the default form submit behavior which is a page reload
        setLoading(true);
        AppEpisodeApi.createEpisodeMapping(episodeMapping)
            .then((response: EpisodeMapping) => {
                toast.success(`Created an episode mapping with ID: ${response.episodeId}.`);
                navigate(viewDatastorePath(datastoreId as string));
            })
            .catch(error => toast.error(<>Episode mapping <strong>{episodeMapping.episodeId}</strong> could not be saved.
                Reason: {error.message}</>))
            .finally(() => setLoading(false))
    };

    return (
        <Container>
            <Row>
                <Col md={12} className="mb-3">
                    <h2>Add new Episode Mapping</h2>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <EpisodeMappingForm
                        episodeDirectories={episodeDirectories}
                        selectedEpisodeDirectory={selectedEpisodeDirectory}
                        setSelectedEpisodeDirectory={setSelectedEpisodeDirectory}
                        episodeMapping={episodeMapping}
                        setEpisodeMapping={setEpisodeMapping}
                        buttonText="Create"
                        onSubmit={handleSubmitCreate}
                    />
                </Col>
                <Col md={6}>
                    <Alert variant="secondary">
                        <p>Fill in the Episode Mapping information and click 'Create'.</p>
                        <p>
                            Note that there cannot be two episode mappings with the same episode id or two mappings pointing to the same
                            directory. Normally, episode id should be set to episode code of the episode, but when adding a new
                            version of an existing episode a different episode id can be used. Remember to change the id back to the
                            episode code after the mapping for the old version is no longer needed
                        </p>
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
}

export default memo(AddEpisodeMapping)