/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {EpisodeDirectory, EpisodeMapping, EpisodeTreeNode,} from "./interfaces";
import {createAxiosWithDefaultInteceptors, createAxiosWithResponseBeanHandling} from "./AppApi";

const axiosDefault = createAxiosWithDefaultInteceptors()
const axiosForResponseBean = createAxiosWithResponseBeanHandling();

/**
 * Episode's application api class. Contains functions used for managing episode's mappings.
 */
export class AppEpisodeApi {

    static listEpisodeDirectory(dsId: string, directoryId: string): Promise<EpisodeTreeNode[]> {
        return axiosDefault.get<EpisodeTreeNode[]>(`/episode/${dsId}/list`, {
            params: {id: directoryId}
        }).then(response => response.data);
    }

    static getEpisodeDirectories(dsId: string): Promise<EpisodeDirectory[]> {
        return axiosForResponseBean.get<EpisodeDirectory[]>(`/episode-directories/${dsId}`)
            .then(response => response.data);
    }

    static createEpisodeMapping(episodeMapping: EpisodeMapping): Promise<EpisodeMapping> {
        return axiosForResponseBean.put<EpisodeMapping>(`/episode`, episodeMapping)
            .then(response => response.data);
    }

    static updateEpisodeMapping(episodeId: string, episodeMapping: EpisodeMapping): Promise<EpisodeMapping> {
        return axiosForResponseBean.post<EpisodeMapping>(`/episode/${episodeId}`, episodeMapping)
            .then(response => response.data);
    }

    static getEpisodeMapping(episodeId: string): Promise<EpisodeMapping> {
        return axiosForResponseBean.get<EpisodeMapping>(`/episode/${episodeId}`)
            .then(response => response.data);
    }

    static deleteEpisodeMapping(episodeId: string): Promise<EpisodeMapping> {
        return axiosForResponseBean.delete<EpisodeMapping>(`/episode/${episodeId}`)
            .then(response => response.data);
    }

}