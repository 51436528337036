/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {useEffect, useState} from "react"
import {Seiscomp} from "../../../api/interfaces"
import {AppSeiscompApi} from "../../../api/AppSeiscompApi"
import {useNavigate, useParams} from "react-router"
import {Alert, Button, Container} from "react-bootstrap"
import SeiscompViewNetwork from "./SeiscompViewNetwork"
import {unspecifiedErrorMessage} from "../../../constants"
import {seiscompsPath} from "../../../routes"
import {useLoading} from "../../../layouts/Main"
import {toast} from "react-toastify";

/**
 * Seiscomp view component
 */
export default function SeiscompView() {
    const navigate = useNavigate()
    const { host, login } = useParams()
    const [seiscomp, setSeiscomp] = useState<Seiscomp>({id: "", host: "", dcid: "", login: "", password: "", networks: []} as Seiscomp)
    const noElementFoundMessage = "No element found.";
    const { setLoading } = useLoading();

    useEffect(() => {
        if (host === undefined || login === undefined) {
            toast.error(noElementFoundMessage)
            navigate(seiscompsPath);
            return;
        }
        setLoading(true);
        AppSeiscompApi.getSeiscomp({host: host, login: login})
            .then(setSeiscomp)
            .catch((e) => {
                toast.error(e.message ?? unspecifiedErrorMessage);
                navigate(seiscompsPath);
            })
            .finally(() => setLoading(false))
    }, [])

    return <Container className="vertical-container">
        <h1>Seiscomp connection preview</h1>
        <Alert variant="info">
            <strong>Host:</strong> {seiscomp.host}
        </Alert>
        <Alert variant="info">
            <strong>DCID:</strong> {seiscomp.dcid}
        </Alert>
        <Alert variant="info">
            <strong>Login:</strong> {seiscomp.login}
        </Alert>
        {seiscomp.networks.map(network => <SeiscompViewNetwork 
            key={`${seiscomp.id}${network.code}`} 
            seiscompId={`${seiscomp.id}`} 
            network={network}/>)}
        <div className="horizontal-container">
            <Button 
                variant="success" 
                onClick={() => navigate(seiscompsPath) }>
                Back to connection list
            </Button>
        </div>
    </Container>
}