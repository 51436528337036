/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import './App.scss';
import Home from "./pages/home/Home";
import Seiscomps from "./pages/seiscomps/Seiscomps/Seiscomps";
import {createBrowserRouter} from "react-router-dom";
import {Navigate, RouterProvider} from "react-router";
import Main from "./layouts/Main";
import SeiscompView from './pages/seiscomps/SeiscompView/SeiscompView';
import SeiscompEdit from './pages/seiscomps/SeiscompEdit';
import SeiscompCreate from './pages/seiscomps/SeiscompCreate';
import {
    addCDGPConnectionPath,
    addCibisConnectionPath,
    addEpisodePathForRouter,
    addSeiscompPath,
    datastoresPath,
    editEpisodePathForRouter,
    editSeiscompPathForRouter,
    hostOnlySeiscompPathForRouter,
    loginPath,
    mainPagePath,
    metadataPreviewPath,
    seiscompsPath,
    viewDatastorePathForRouter,
    viewSeiscompPathForRouter
} from './routes';
import Login from './pages/login/Login';
import AddCibisConnection from "./pages/datastores/AddCibisConnection";
import AddCDGPConnection from "./pages/datastores/AddCDGPConnection";
import Datastores from "./pages/datastores/Datastores";
import DatastoresConnectionPreview from "./pages/datastores/DatastoreConnectionPreview";
import React from "react";
import AddEpisodeMappingComponent from "./pages/episodes/AddEpisodeMapping";
import EditEpisodeMappingComponent from "./pages/episodes/EditEpisodeMapping";
import MetadataPreview from "./pages/datastores/MetadataPreview";


/**
 * Routing function - enables routing pages in application.
 */
export const router = createBrowserRouter([
    {
        path: mainPagePath,
        element: <Main/>,
        children: [
            {
                element: <Home/>,
                index: true
            },
            // Seiscomps
            {
                path: seiscompsPath,
                element: <Seiscomps/>
            },
            {
                path: addSeiscompPath,
                element: <SeiscompCreate/>
            },
            {
                path: hostOnlySeiscompPathForRouter,
                element: <Navigate to={seiscompsPath}/>
            },
            {
                path: viewSeiscompPathForRouter,
                element: <SeiscompView/>
            },
            {
                path: editSeiscompPathForRouter,
                element: <SeiscompEdit/>
            },
            // Datastores
            {
                path: datastoresPath,
                element: <Datastores/>
            },
            {
                path: addCibisConnectionPath,
                element: <AddCibisConnection/>
            },
            {
                path: addCDGPConnectionPath,
                element: <AddCDGPConnection/>
            },
            {
                path: viewDatastorePathForRouter,
                element: <DatastoresConnectionPreview/>
            },
            {
                path: addEpisodePathForRouter,
                element: <AddEpisodeMappingComponent/>
            },
            {
                path: editEpisodePathForRouter,
                element: <EditEpisodeMappingComponent/>
            }, {
                path: metadataPreviewPath,
                element: <MetadataPreview/>
            },
        ],
    },
    {
        path: loginPath,
        element: <Login/>
    },
    {
        path: "*", //fallback path
        element: <Navigate to={mainPagePath}/>
    },
]);

/**
 * Main application component.
 */
function App() {
    return (
        <div className="App">
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;
