/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {memo, useState} from "react"
import {SeiscompCredentials} from "../../api/interfaces"
import {Form, InputGroup} from "react-bootstrap"

interface SeiscompCredentialsInputProps extends SeiscompCredentials {
    setSeiscompProperty: (propertyName: string, value: any) => void,
    isEdit: boolean,
};

/**
 * Seiscomp credentials input component (for edit and create)
 */
function SeiscompCredentialsInput(props: SeiscompCredentialsInputProps) {
    const {setSeiscompProperty, isEdit, host, dcid, login} = props;

    // Enable password input for creation mode, and conditionally for edit mode.
    const [isPasswordEditable, setPasswordEditable] = useState(!isEdit);

    return <>
        <InputGroup className="mb-2">
            <InputGroup.Text id="host">Host:port</InputGroup.Text>
            <Form.Control
                disabled={isEdit}
                placeholder="host"
                value={host}
                onChange={(e) => setSeiscompProperty("host", e.target.value)}
            />
        </InputGroup>
        <InputGroup className="mb-2">
            <InputGroup.Text id="dcid">DCID</InputGroup.Text>
            <Form.Control
                placeholder="dcid"
                value={dcid}
                onChange={(e) => setSeiscompProperty("dcid", e.target.value)}
            />
        </InputGroup>
        <InputGroup className="mb-2">
            <InputGroup.Text
                id="user">User</InputGroup.Text>
            <Form.Control
                disabled={isEdit}
                placeholder="login"
                value={login}
                onChange={(e) => setSeiscompProperty("login", e.target.value)}
            />
        </InputGroup>

        {isEdit &&
            // Toggle for editing the password in edit mode.
            <InputGroup className="mt-2">
                <Form.Label>Edit Password</Form.Label>
                <Form.Switch
                    className="ms-2"
                    checked={isPasswordEditable}
                    onChange={() => setPasswordEditable(!isPasswordEditable)}
                />
            </InputGroup>
        }

        {/*Display password field in create mode. In edit mode, display based on toggle.*/}
        {/*Avoiding password re-entry in edit mode for better UX and security.*/}
        {isPasswordEditable &&
            <InputGroup className="mb-3">
                <InputGroup.Text id="password">Password</InputGroup.Text>
                <Form.Control
                    type="password"
                    placeholder="password"
                    value={props.password}
                    onChange={(e) => setSeiscompProperty("password", e.target.value)}
                />
            </InputGroup>
        }
    </>
}

export default memo(SeiscompCredentialsInput)