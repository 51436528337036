/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {Button, Modal} from "react-bootstrap";
import React, {useState} from "react";

interface ConfirmationModalProps {
    title: string;
    body: React.ReactNode;
    show: boolean;
    onConfirm: () => void;
    onClose: () => void;
    confirmLabel?: string; // can be optionally overwritten
    closeLabel?: string; // can be optionally overwritten
}

/**
 * `ConfirmationModal` Component. A modal for user confirmations.
 *
 * Props:
 * - `title`: Modal's title.
 * - `body`: Content displayed in modal.
 * - `show`: If true, modal is visible.
 * - `onConfirm`: Callback for confirmation.
 * - `onClose`: Callback to close modal.
 * - `confirmLabel` (optional): Confirm button's label (default: "Yes").
 * - `closeLabel` (optional): Close button's label (default: "No").
 *
 * Usage:
 * ```jsx
 * <ConfirmationModal
 *     title="Confirm"
 *     body="Are you sure?"
 *     show={isModalOpen}
 *     onConfirm={handleConfirm}
 *     onClose={closeModal}
 * />
 * ```
 */
export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
                                                             title,
                                                             body,
                                                             show,
                                                             onConfirm,
                                                             onClose,
                                                             confirmLabel = "Yes",
                                                             closeLabel = "No"
                                                         }) => {

    const handleConfirmAndClose = () => {
        onConfirm();
        onClose();
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleConfirmAndClose}>{confirmLabel}</Button>
                <Button variant="secondary" onClick={onClose}>{closeLabel}</Button>
            </Modal.Footer>
        </Modal>
    );
};

/**
 * A custom hook for managing modal states.
 *
 * @param {boolean} initialState - Initial state of the modal's visibility.
 *
 * @returns {{
 *   isShown: boolean,          // Indicates whether the modal is currently shown.
 *   showModal: Function,       // Function to show the modal.
 *   hideModal: Function        // Function to hide the modal.
 * }}
 */
export const useModalManager = (initialState = false) => {
    const [isShown, setIsShown] = useState(initialState);

    const showModal = () => setIsShown(true);
    const hideModal = () => setIsShown(false);

    return {
        isShown,
        showModal,
        hideModal
    };
};

