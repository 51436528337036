/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */

import {DatastoreInfo} from "../../api/interfaces";
import {memo} from "react";
import {Button} from "react-bootstrap";
import {viewDatastorePath} from "../../routes";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";

/**
 * Datastore view table entry component
 */
function DatastoreTableEntry(datastoreInfo: DatastoreInfo) {

    const navigate = useNavigate();

    return <tr>
        <td>
            <Link to={viewDatastorePath(datastoreInfo.id)}>{datastoreInfo.id}</Link>
        </td>
        <td>{datastoreInfo.url}</td>
        <td>{datastoreInfo.type}</td>
        <td>
            <Button
                variant="info"
                size="sm"
                onClick={() => navigate(viewDatastorePath(datastoreInfo.id))}>
                details
            </Button>
        </td>
    </tr>
}

function arePropsEqual(previousDSInfo: DatastoreInfo, nextDSInfo: DatastoreInfo) {
    return previousDSInfo.id === nextDSInfo.id
        // Checking just ID may not be enough as other parameters may be edited. When e.g. url is edited and
        // ID is the same the component should be rendered again.
        && previousDSInfo.url === nextDSInfo.url
        && previousDSInfo.type === nextDSInfo.type
}

export default memo(DatastoreTableEntry, arePropsEqual)