/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {ConnectionProperties, EpisodeMapping} from "../../api/interfaces";
import {Button, Placeholder} from "react-bootstrap";
import {FaEdit, FaTrash} from "react-icons/fa";
import {ConfirmationModal, useModalManager} from "../../components/ConfirmationModal";
import React from "react";
import {useToastActionUtil} from "../../components/useToastActionUtil";
import {editEpisodePath} from "../../routes";
import {AppEpisodeApi} from "../../api/AppEpisodeApi";
import {useNavigate} from "react-router";

interface DirectoryTreeEpisodePanelProps {
    selectedEpisode: EpisodeMapping | undefined
    setSelectedEpisode: React.Dispatch<React.SetStateAction<EpisodeMapping | undefined>>;
    datastoreId: string;
    loadEpisodesDirectories: () => void;
    showLoadingEpisodePlaceholder: boolean;
    isReadOnly: boolean;
}

/**
 * Renders a panel for a selected episode in the directory tree. Shows the episode metadata and allows to edit or remove the episode.
 */
function DirectoryTreeEpisodePanel<T extends ConnectionProperties>(props: DirectoryTreeEpisodePanelProps) {

    const {selectedEpisode, setSelectedEpisode, datastoreId, loadEpisodesDirectories, showLoadingEpisodePlaceholder, isReadOnly} = props;

    const navigate = useNavigate()
    const {handleAxiosResponseWithToast} = useToastActionUtil();
    const deleteEpisodeModal = useModalManager();


    const removeEpisode = (selectedEpisodeParam: EpisodeMapping) => {
        setSelectedEpisode(undefined)
        handleAxiosResponseWithToast(
            () => AppEpisodeApi.deleteEpisodeMapping(selectedEpisodeParam.episodeId),
            <>Removing episode <strong>{selectedEpisodeParam.episodeId}</strong>...</>,
            () => <>Removed episode <strong>{selectedEpisodeParam.episodeId}</strong>.</>,
            (e, errMsg) => <>Episode mapping <strong>{selectedEpisodeParam.episodeId}</strong> could not be removed.
                Reason: {errMsg}</>,
            loadEpisodesDirectories,
        );
    }

    return (<>
            {showLoadingEpisodePlaceholder && <>
                {!isReadOnly && <>
                    <Placeholder.Button xs={2} variant={"danger"} aria-hidden="true" className='mb-2 me-1'/>
                    <Placeholder.Button xs={3} aria-hidden="true" className='mb-2 me-1'/>
                </>}
                <div style={{overflow: 'auto'}}>
                    <Placeholder as="pre" className='pre-with-border' animation="glow">
                        <Placeholder xs={1}/><br></br>
                        {Array.from({length: 5}).map((_, i) => (
                            <React.Fragment key={i}><Placeholder xs={11}/><br/></React.Fragment>
                        ))}
                        <Placeholder xs={1}/><br></br>
                    </Placeholder>
                </div>
            </>}

            {selectedEpisode && <>
                {!isReadOnly &&
                    <div className="update-buttons">
                        <Button variant="danger" onClick={deleteEpisodeModal.showModal} className='mb-2 me-1'>
                            remove episode <FaTrash/>
                        </Button>
                        <ConfirmationModal
                            title="Remove episode"
                            body={<><p>Do you want to remove episode <strong>{selectedEpisode.episodeId}</strong> from the Data Store
                                id=<strong>{datastoreId}</strong>?</p>
                            </>}
                            show={deleteEpisodeModal.isShown}
                            onConfirm={() => removeEpisode(selectedEpisode)}
                            onClose={deleteEpisodeModal.hideModal}
                        />
                        <Button variant="primary"
                                onClick={() => navigate(editEpisodePath(datastoreId, selectedEpisode.episodeId))}
                                className='mb-2 me-1'>
                            change episode <FaEdit/>
                        </Button>
                    </div>
                }
                <div style={{overflow: 'auto'}}>
                    <pre className='pre-with-border'>{JSON.stringify(selectedEpisode, null, 2)}</pre>
                </div>
            </>}

        </>
    );
}

export default DirectoryTreeEpisodePanel;