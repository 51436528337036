/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Row} from 'react-bootstrap';
import DirectoryTree, {TreeMode} from '../DirectoryTree/DirectoryTree';
import {toast} from 'react-toastify';
import {AppDatastoreApi} from '../../api/AppDatastoreApi';
import {useNavigate} from 'react-router-dom';
import {useLoading} from "../../layouts/Main";
import {DatastoreInfo} from "../../api/interfaces";
import {viewDatastorePath} from "../../routes";

/**
 * MetadataPreview Component
 *
 * Shows a preview of all connected Datastores and their associated metadata.
 * Metadata is displayed using the {@link DirectoryTree} component in a read-only mode.
 */
const MetadataPreview = () => {
    const {setLoading} = useLoading();
    const [datastoresInfo, setDatastoresInfo] = useState([] as DatastoreInfo[]);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        AppDatastoreApi.getAllDatastores()
            .then(setDatastoresInfo)
            .catch((e) => toast.error('Unknown error while fetching datastores: ' + e))
            .finally(() => setLoading(false))
    }, []);

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h2>Metadata preview</h2>
                    {datastoresInfo.length === 0 && <Alert variant="info">No Data Store connections defined.</Alert>}
                </Col>
            </Row>
            <Row>
                <Col md={12} style={{overflow: 'auto'}}>
                    {datastoresInfo.map(datastoreInfo => (
                        <div key={datastoreInfo.id} className="mt-3">
                            <h4>
                                {datastoreInfo.id}
                                <Button variant="info" className={'ms-2 mb-1'} size="sm"
                                        onClick={() => navigate(viewDatastorePath(datastoreInfo.id))}>
                                    details
                                </Button>
                            </h4>
                            <DirectoryTree datastoreId={datastoreInfo.id} treeMode={TreeMode.Metadata} isReadOnly={true}/>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}

export default MetadataPreview;
