/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {ConnectionProperties} from "../../api/interfaces";
import {useNavigate, useParams} from "react-router";
import {FaCheck, FaDownload, FaPlus, FaTrash} from "react-icons/fa";
import {addEpisodePath} from "../../routes";


import React, {useEffect} from "react";
import {Button, Col, Container, Dropdown, Row} from "react-bootstrap";
import {ConfirmationModal, useModalManager} from "../../components/ConfirmationModal";
import DirectoryTree, {TreeMode} from "../DirectoryTree/DirectoryTree";
import {useVerifyMetadata} from "../DirectoryTree/useVerifyMetadata";
import {useDatastoreApi} from "./useDatastorePreviewActions";

/**
 * DatastoresConnectionPreview Component
 *
 * This component provides a detailed view of a specific Datastore connection.
 * It displays essential metadata of the Datastore and offers various management options,
 * such as deleting the Datastore, managing its metadata, verifying metadata consistency,
 * and navigating to related resources (e.g., episodes).
 *
 * It also displays a {@link DirectoryTree}s with all episodes and metadata associated with the Datastore.
 */
 export default function DatastoresConnectionPreview<T extends ConnectionProperties>() {

    const navigate = useNavigate()
    const {verifyMetadataConsistency} = useVerifyMetadata();

    const {id} = useParams<{ id: string }>();
    const {
        datastore,
        loadDatastore,
        removeDatastore,
        removeAllMetadata,
        loadNewMetadata,
        reloadAllMetadata
    } = useDatastoreApi(id);

    const deleteDSModal = useModalManager();
    const deleteMetadataModal = useModalManager();
    const loadNewMetadataModal = useModalManager();
    const reloadAllMetadataModal = useModalManager();

    useEffect(() => {
        loadDatastore();
    }, [id]);

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h2>Information about connection to Data Store {datastore?.storeType} with id=<b>{id}</b></h2>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <pre className='pre-with-border'>{JSON.stringify(datastore, null, 2)}</pre>
                </Col>
                <Col md={4}>
                    <Button variant="danger" onClick={deleteDSModal.showModal} className="mb-2 me-1">
                        Remove this Data Store <FaTrash/>
                    </Button>
                    <ConfirmationModal
                        title="Remove Data Store"
                        body={<>Do you want to remove Data Store <strong>{id}</strong> along with all its associated metadata?</>}
                        show={deleteDSModal.isShown}
                        onConfirm={removeDatastore}
                        onClose={deleteDSModal.hideModal}
                    />

                    <Button variant="warning" onClick={deleteMetadataModal.showModal} className="mb-2 me-1">
                        Remove metadata from this Data Store <FaTrash/>
                    </Button>
                    <ConfirmationModal
                        title="Remove metadata from Data Store"
                        body={<>Do you want to remove all metadata associated with Data Store <strong>{id}</strong>?</>}
                        show={deleteMetadataModal.isShown}
                        onConfirm={removeAllMetadata}
                        onClose={deleteMetadataModal.hideModal}
                    />

                    <Dropdown className="mb-2 me-1">
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">Update metadata <FaDownload/></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={loadNewMetadataModal.showModal}>
                                Load new/changed metadata
                            </Dropdown.Item>
                            <ConfirmationModal
                                title="Load new/changed metadata"
                                body={<><p>This operation will check for new or updated files in the Data Store.
                                    It will save them and remove all files that no longer exist in the Data Store.
                                    This process may take up to over several minutes or longer, depending on the number of new/updated
                                    files.</p>
                                    <p>Do you want to continue for Data Store with id=<strong>{id}</strong>?</p>
                                </>}
                                show={loadNewMetadataModal.isShown}
                                onConfirm={loadNewMetadata}
                                onClose={loadNewMetadataModal.hideModal}
                            />

                            <Dropdown.Item onClick={reloadAllMetadataModal.showModal}>
                                Reload all metadata
                            </Dropdown.Item>
                            <ConfirmationModal
                                title="Reload all metadata"
                                body={<><p>This operation will remove <strong>ALL</strong> metadata fetched from this Data Store and load
                                    them again from the server. This process may take up to over several minutes or even longer, depending
                                    on the number of files in the Data Store.</p>
                                    <p>Do you want to continue for Data Store id=<strong>{id}</strong>?</p>
                                </>}
                                show={reloadAllMetadataModal.isShown}
                                onConfirm={reloadAllMetadata}
                                onClose={reloadAllMetadataModal.hideModal}
                            />

                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="info" onClick={() => verifyMetadataConsistency(id as string)} className="mb-2 me-1">
                        Verify metadata consistency <FaCheck/>
                    </Button>
                    <Button variant="success" onClick={() => navigate(addEpisodePath(id as string))} className="mb-2 me-1">
                        Add Episode <FaPlus/>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h3>Episodes from {datastore?.storeType}: {datastore?.id}</h3>
                    {datastore && <DirectoryTree datastore={datastore} datastoreId={datastore?.id} treeMode={TreeMode.Episodes} isReadOnly={false}/>}
                </Col>
                <Col md={12}>
                    <h3>Metadata from {datastore?.storeType}: {datastore?.id}</h3>
                    {datastore && <DirectoryTree datastore={datastore} datastoreId={datastore?.id} treeMode={TreeMode.Metadata} isReadOnly={false}/>}
                </Col>
            </Row>
        </Container>
    );
};