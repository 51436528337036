/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
import {Form} from "react-bootstrap"
import {SeiscompStation} from "../../api/interfaces"
import {memo} from "react";
import {datacenterDateFormat} from "../../constants";

interface SeiscompNetworkInputStationsTableEntryProps {
    station : SeiscompStation,
    episodesList : string[],
    setSpecificStationProperty: (stationIndex: number, satationPropery: string, value: any) => void,
    indexInStationList: number,
}

/**
 * Seiscomp input network station component
 */
function SeiscompNetworkInputStationsTableEntry(props: SeiscompNetworkInputStationsTableEntryProps) {
    const {station, episodesList, setSpecificStationProperty, indexInStationList} = props;

    const arrayDelimiter = ","
    const setStationProperty = (satationPropery: string, value: any) => {
        setSpecificStationProperty(indexInStationList, satationPropery, value);
    }
    dayjs.extend(utc)
    
    return <tr>
        <td>
            <Form.Select 
                onChange={(e) => setStationProperty("episode", e.target.value)}
                value={station.episode}>
                <option value={""}></option>
                {episodesList.sort().map(episode => <option
                    key={episode} 
                    value={episode}>{episode}
                </option>)}
            </Form.Select>
        </td>
        <td>
            <Form.Control
                onChange={(e) => setStationProperty("allowedDownload", e.target.value.trim().split(arrayDelimiter))} //If optimization is needed the input could be held in one string. The alternative would be not to represent it as one input.  
                type="text"
                value={station.allowedDownload?.reduce((accumulator, value) => accumulator === "" ? `${value}` : `${accumulator}${arrayDelimiter}${value}`, "")}/>
        </td>
        <td>{station.code}</td>
        <td>{station.description}</td>
        <td>{station.start ? dayjs.utc(station.start).format(datacenterDateFormat) : ""}</td>
        <td>{station.end ? dayjs.utc(station.end).format(datacenterDateFormat) : ""}</td>
        <td>{station.latitude}, {station.longitude}</td>
        <td>{station.elevation}</td>
    </tr>
}

export default memo(SeiscompNetworkInputStationsTableEntry)