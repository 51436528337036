/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {useCallback, useState} from "react";
import {ConnectionProperties, ConnectionTestResponse, DatastoreProperties} from "../../api/interfaces";
import {useLoading} from "../../layouts/Main";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";
import {viewDatastorePath} from "../../routes";

/**
 * Custom hook that handles creating a new datastore connection. (Cibis, CDGP).
 *
 * @template T - A type that extends {@link ConnectionProperties}.
 * @param {function(T): Promise<ConnectionTestResponse>} testConnectionApiRequest - A function for testing the connection.
 * @param {function(DatastoreProperties<T>): Promise<{id: string}>} addConnectionApiRequest - A function for adding a new connection.
 *
 * @returns {Object} The properties and handlers related to the datastore connection.
 */
export const useCreateDatastoreConnection = <T extends ConnectionProperties>(
    testConnectionApiRequest: (connectionProperties: T) => Promise<ConnectionTestResponse>,
    addConnectionApiRequest: (datastoreProperties: DatastoreProperties<T>) => Promise<{ id: string }>,
) => {

    const {setLoading} = useLoading();
    const navigate = useNavigate();

    const [datastoreProperties, setDatastoreProperties] = useState<DatastoreProperties<T>>({
        connectionProperties: {} as T,
        contactEmails: ''
    });

    const [connectionTestResponse, setConnectionTestResponse] = useState<ConnectionTestResponse | null>(null)

    const setConnectionProperty = useCallback((propertyName: string, value: any) => {
        setDatastoreProperties((currentValue) => ({
            ...currentValue,
            connectionProperties: {
                ...currentValue.connectionProperties,
                [propertyName]: value
            }
        }))
    }, [])

    const setDataStoreProperty = useCallback((propertyName: string, value: any) => {
        setDatastoreProperties((currentValue) => ({
            ...currentValue,
            [propertyName]: value
        }))
    }, [])

    const handleTestButton = useCallback(() => {
        setLoading(true);
        testConnectionApiRequest(datastoreProperties.connectionProperties)
            .then(setConnectionTestResponse)
            .catch((e) => {
                toast.error('Unknown error while testing the connection: ' + e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, datastoreProperties, testConnectionApiRequest]);

    const handleSaveButton = useCallback(() => {
        if (!connectionTestResponse || !connectionTestResponse.success) {
            toast.warning('Fill in the connection information and press "Test"');
            return;
        }
        setLoading(true);
        addConnectionApiRequest(datastoreProperties)
            .then(response => navigate(viewDatastorePath(response.id)))
            .catch((e) => {
                toast.error('Unknown error. Please refresh the page and try again: ' + e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, datastoreProperties, addConnectionApiRequest, connectionTestResponse]);

    return {
        datastoreProperties,
        setConnectionProperty,
        setDataStoreProperty,
        handleTestButton,
        handleSaveButton,
        connectionTestResponse,
    }
}
