/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import React from 'react';
import {Alert, Button, Form} from 'react-bootstrap';
import {EpisodeDirectory, EpisodeMapping} from "../../api/interfaces";

interface Props {
    episodeDirectories: EpisodeDirectory[],
    selectedEpisodeDirectory: EpisodeDirectory | null,
    setSelectedEpisodeDirectory: (value: (((prevState: (EpisodeDirectory)) => (EpisodeDirectory)) | EpisodeDirectory)) => void,
    episodeMapping: EpisodeMapping,
    setEpisodeMapping: React.Dispatch<React.SetStateAction<EpisodeMapping>>,
    buttonText: string,
    onSubmit: (event: React.FormEvent<HTMLFormElement>, episodeMapping: EpisodeMapping) => Promise<void>,
}

/**
 * A reusable form component for creating or editing episode mappings.
 * Displays available episode directories and allows setting an episode ID.
 *
 * Props:
 * - episodeDirectories: List of available episode directories.
 * - selectedEpisodeDirectory: Currently selected episode directory.
 * - setSelectedEpisodeDirectory: Handler to set the selected episode directory.
 * - episodeMapping: Current episode mapping details.
 * - setEpisodeMapping: Handler to set episode mapping details.
 * - buttonText: Text to display on the form's submit button.
 * - onSubmit: Function to handle form submission.
 */
export const EpisodeMappingForm: React.FC<Props> = ({
                                                        episodeDirectories,
                                                        episodeMapping,
                                                        setEpisodeMapping,
                                                        buttonText,
                                                        onSubmit,
                                                        selectedEpisodeDirectory,
                                                        setSelectedEpisodeDirectory
                                                    }) => {

    const handleDirectoryChange = (directoryPath: string) => {
        const dir = episodeDirectories.find(dir => dir.path === directoryPath);
        if (dir) {
            setSelectedEpisodeDirectory(dir);
            setEpisodeMapping({
                ...episodeMapping,
                episodeId: dir.episodeCode,
                episodeDirectoryPath: dir.path,
                episodeDirectoryDsEntryId: dir.dsEntryId,
            });
        }
    };

    return (


        <Form onSubmit={(event) => onSubmit(event, episodeMapping)}>
            {episodeDirectories.length === 0 &&
                <Alert variant="warning">No episode directories found for this datastore.</Alert>
            }
            <Form.Group className="mb-3">
                <Form.Label>Episode Directory</Form.Label>
                <Form.Select as="select"
                             required
                             value={selectedEpisodeDirectory?.path}
                             onChange={e => handleDirectoryChange(e.target.value)}>
                    <option value="" disabled={true}>Select a directory...</option>
                    {episodeDirectories.map(dir => (
                        <option key={dir.path} value={dir.path}>{dir.path}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Episode Id</Form.Label>
                <Form.Control
                    type="text"
                    required
                    value={episodeMapping.episodeId}
                    onChange={e => setEpisodeMapping({...episodeMapping, episodeId: e.target.value})}
                />
            </Form.Group>
            <Button variant="success" type="submit">{buttonText}</Button>
        </Form>
    );
};
