/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {memo} from "react";
import {useNavigate} from "react-router"
import {SeiscompInfo} from "../../../api/interfaces"
import {Button} from "react-bootstrap";
import {unspecifiedErrorMessage} from "../../../constants";
import {editSeiscompPath, viewSeiscompPath} from "../../../routes";
import {AppSeiscompApi} from "../../../api/AppSeiscompApi";
import {toast} from "react-toastify";
import {ConfirmationModal, useModalManager} from "../../../components/ConfirmationModal";

interface SeiscompsTableEntryProps {
    seiscompInfo: SeiscompInfo,
    setLoading: (isLoading: boolean) => void,
    addTemporaryMessage:(message: string) => void,
    deleteSelf: () => void,
}

/**
 * Seiscomps view table entry component
 */
function SeiscompsTableEntry(props: SeiscompsTableEntryProps) {
    const {seiscompInfo, setLoading, addTemporaryMessage, deleteSelf} = props;
    const navigate = useNavigate();
    const encodedHost = encodeURIComponent(seiscompInfo.host);
    const encodedLogin = encodeURIComponent(seiscompInfo.login);
    const deleteSeiscompModal = useModalManager();

    const handleDelete = () => {
        deleteSeiscompModal.hideModal()
        setLoading(true);
        AppSeiscompApi.deleteSeiscomp(seiscompInfo.host, seiscompInfo.login)
            .then(() => {
                toast.success("Seiscomp configuration successfully removed.")
                deleteSelf();
            })
            .catch((e) => toast.error(e.message ?? unspecifiedErrorMessage))
            .finally(() => setLoading(false))
    }

    return <tr>
        <td>{seiscompInfo.host}</td>
        <td>{seiscompInfo.login}</td>
        <td className="horizontal-container">
            <Button 
                variant="info" 
                size="sm" 
                onClick={() => navigate(viewSeiscompPath(encodedHost, encodedLogin))}>
                View
            </Button>
            <Button 
                variant="warning" 
                size="sm" 
                onClick={() => navigate(editSeiscompPath(encodedHost, encodedLogin))}>
                Edit
            </Button>
            <Button 
                variant="danger" 
                size="sm" 
                onClick={deleteSeiscompModal.showModal}>
                Delete
            </Button>
            <ConfirmationModal title={`Delete Seiscomp`}
                               body={<>Do you want to remove Seiscomp <strong>{seiscompInfo.host}</strong>? TCS will not be able to access
                                   its data anymore.</>}
                               show={deleteSeiscompModal.isShown}
                               onConfirm={handleDelete}
                               onClose={deleteSeiscompModal.hideModal}/>
        </td>
    </tr>

}

function arePropsEqual(previousProps: SeiscompsTableEntryProps, nextProps: SeiscompsTableEntryProps) {
   return previousProps.seiscompInfo.host === nextProps.seiscompInfo.host
    && previousProps.seiscompInfo.login === nextProps.seiscompInfo.login
}

export default memo(SeiscompsTableEntry, arePropsEqual)