/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {AppDatastoreApi} from "../../api/AppDatastoreApi";
import {AddDatastoreConnection} from './AddDatastoreConnection';
import CDGPConnectionInputForm from "./CDGPConnectionInputForm";

/**
 * Component for creating a CDGP connection using the {@link AddDatastoreConnection}.
 * It specifies the connection setup instructions, the API request handlers from {@link AppDatastoreApi},
 * and the {@link CDGPCredentialsInput} component for inputting credentials.
 *
 * @returns {JSX.Element} The customized CreateDatastoreConnectionComponent for creating a CDGP connection.
 */
export default function AddCDGPConnection() {
    return <AddDatastoreConnection
        title="Adding new CDGP connection"
        instructions={[
            '1. Fill in the data required to set up the connection to new CDGP',
            '2. Press "Test" to check the connection',
            '3. Press "Save" to save the connection details',
            '4. Import the data from CDGP',
        ]}
        testConnectionApiRequest={AppDatastoreApi.testCDGPConnection}
        addConnectionApiRequest={AppDatastoreApi.addCDGPConnection}
        CredentialsInputFormComponent={CDGPConnectionInputForm}
    />;
}