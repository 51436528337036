/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {Button, Container, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {DatastoreInfo} from "../../api/interfaces";
import {useLoading} from "../../layouts/Main";
import {useNavigate} from "react-router";
import {AppDatastoreApi} from "../../api/AppDatastoreApi";
import DatastoresTableEntry from "./DatastoresTableEntry";
import {FaFilm, FaPlus, FaSync} from "react-icons/fa";
import {addCDGPConnectionPath, addCibisConnectionPath, metadataPreviewPath} from "../../routes";
import {toast} from 'react-toastify';

import {useToastActionUtil} from "../../components/useToastActionUtil";


/**
 * Datastores view component
 */
export default function Datastores() {

    const [allDatastoreInfo, setAllDatastoreInfo] = useState<DatastoreInfo[]>([]);
    const navigate = useNavigate()
    const {setLoading} = useLoading();
    const {handleAxiosResponseWithToast} = useToastActionUtil();

    useEffect(() => {
        setLoading(true)
        AppDatastoreApi.getAllDatastores()
            .then(setAllDatastoreInfo)
            .catch((e) => toast.error('Unknown error while fetching datastores: ' + e))
            .finally(() => setLoading(false))
    }, [])

    const refreshDatastoreConnectionCache = () => {
        handleAxiosResponseWithToast(
            () => AppDatastoreApi.refreshDatastoreConnectionCache(),
            <>Refreshing cached connections...</>,
            () => <>Successfully refreshed cached connections!</>,
            (e, errMsg) => <>Unknown error while refreshing cached connections: {errMsg}</>
        );
    }

    return (
        <Container>
            <h2>All registered connections to Data Stores</h2>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>id</th>
                    <th>url</th>
                    <th>type</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {allDatastoreInfo?.map(datastoreInfo => <DatastoresTableEntry
                    key={`${datastoreInfo.id}${datastoreInfo.url}${datastoreInfo.type}`}
                    {...datastoreInfo}
                />)}
                </tbody>
            </Table>

            <Button
                variant="success"
                className='me-1 mb-1'
                onClick={() => navigate(addCibisConnectionPath)}>
                Add connection to CIBIS <FaPlus/>
            </Button>
            <Button
                variant="success"
                className='me-1 mb-1'
                onClick={() => navigate(addCDGPConnectionPath)}>
                Add connection to CDGP <FaPlus/>
            </Button>
            <Button
                variant="primary"
                className='me-1 mb-1'
                onClick={() => navigate(metadataPreviewPath)}>
                Go to metadata browser <FaFilm/>
            </Button>
            <Button
                variant="primary"
                className='me-1 mb-1'
                onClick={() => refreshDatastoreConnectionCache()}>
                Refresh cached connections <FaSync/>
            </Button>
        </Container>
    )
}