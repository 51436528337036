/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {ConnectionProperties, FileWithMetadata} from "../../api/interfaces";
import {Button, Placeholder} from "react-bootstrap";
import {FaFileDownload, FaRetweet, FaSync, FaTrash} from "react-icons/fa";
import {ConfirmationModal, useModalManager} from "../../components/ConfirmationModal";
import React, {useEffect} from "react";
import {AppFileMetadataApi} from "../../api/AppFileMetadataApi";
import {isAxiosError} from "axios";
import {useVerifyMetadata} from "./useVerifyMetadata";
import {useToastActionUtil} from "../../components/useToastActionUtil";

interface DirectoryTreeFilePanelProps {
    selectedItem: FileWithMetadata | undefined;
    datastoreId: string;
    loadSubtreeForNode: (nodeKey: string | number) => Promise<void>;
    setSelectedItem: React.Dispatch<React.SetStateAction<FileWithMetadata | undefined>>;
    showLoadingFilePlaceholder: boolean;
    showLoadingDirectoryPlaceholder: boolean;
    isReadOnly: boolean;
}

/**
 * Renders a panel for a selected file/directory in the directory tree. Shows the file metadata and management buttons.
 */
function DirectoryTreeFilePanel<T extends ConnectionProperties>(props: DirectoryTreeFilePanelProps) {

    const {
        selectedItem,
        datastoreId,
        loadSubtreeForNode,
        setSelectedItem,
        showLoadingFilePlaceholder,
        showLoadingDirectoryPlaceholder,
        isReadOnly,
    } = props;

    const {verifyMetadataConsistency} = useVerifyMetadata();
    const {handleAxiosResponseWithToast} = useToastActionUtil();
    const deleteFileModal = useModalManager();
    const synchronizeMetadataModal = useModalManager();

    useEffect(() => {
        console.log("showLoadingFilePlaceholder value:", showLoadingFilePlaceholder);
    }, [showLoadingFilePlaceholder]);

    const removeFile = (selectedItemParam: FileWithMetadata) => {
        setSelectedItem(undefined);
        handleAxiosResponseWithToast<void>(
            () => AppFileMetadataApi.deleteItem(selectedItemParam.id),
            <>Removing <strong>{selectedItemParam.name}</strong>...</>,
            () => <>Removed <strong>{selectedItemParam.name}</strong>.</>,
            e => <>Error removing <strong>{selectedItemParam.name}</strong>. Reason: {e.message}</>,
            () => loadSubtreeForNode(selectedItemParam.parentId),
        );
    }

    const onRefreshFileInCache = (selectedItem: FileWithMetadata) => {
        handleAxiosResponseWithToast<void>(
            () => AppFileMetadataApi.refreshFileInCache(selectedItem.id),
            <>Refreshing cache for file <strong>{selectedItem.name}</strong>...</>,
            () => <>Refreshed cache for file <strong>{selectedItem.name}</strong>.</>,
            (e, eMsg) => <>Error while refreshing cache for file <strong>{selectedItem.name}</strong>:{' '}
                {isAxiosError(e) ?
                    (e.response?.status === 404 ? 'File not found' : (e.response?.data?.message))
                    : e.message
                }
            </>,
        )
    }

    const synchronizeMetadataForSubtree = (selectedItem: FileWithMetadata) => {
        handleAxiosResponseWithToast<void>(
            () => AppFileMetadataApi.synchronizeAllMetadataForSubtree(datastoreId, selectedItem.id),
            <>Reloading metadata for <strong>{selectedItem.name}</strong>...</>,
            () => <>Metadata for <strong>{selectedItem.name}</strong> were reloaded.</>,
            e => <>Error while reloading metadata for <strong>{selectedItem.name}</strong>. Reason: {e.message}</>,
            () => verifyMetadataConsistency(datastoreId),
            () => loadSubtreeForNode(selectedItem.parentId),
        )
    }
    return (<>
            {showLoadingDirectoryPlaceholder && <>
                {!isReadOnly && <>
                    <Placeholder.Button xs={2} variant={"danger"} aria-hidden="true" className='mb-2 me-1'/>
                    <Placeholder.Button xs={3} aria-hidden="true" className='mb-2 me-1'/>
                </>}
                <div style={{overflow: 'auto'}}>
                    <Placeholder as="pre" className='pre-with-border' animation="glow">
                        <Placeholder xs={1}/><br></br>
                        {Array.from({length: 5}).map((_, i) => (
                            <React.Fragment key={i}><Placeholder xs={11}/><br/></React.Fragment>
                        ))}
                        <Placeholder xs={1}/><br></br>
                    </Placeholder>
                </div>
            </>}
            {showLoadingFilePlaceholder && <>
                {!isReadOnly && <>
                    <Placeholder.Button style={{width: '115px'}} variant={"secondary"} aria-hidden="true" className='mb-2 me-1'/>
                    <Placeholder.Button style={{width: '100px'}} variant={"danger"} aria-hidden="true" className='mb-2 me-1'/>
                    <Placeholder.Button style={{width: '145px'}} variant={"warning"} aria-hidden="true" className='mb-2 me-1'/>
                    <Placeholder.Button style={{width: '248px'}} variant={"primary"} aria-hidden="true" className='mb-2 me-1'/>
                </>}
                <div style={{overflow: 'auto'}}>
                    <Placeholder as="pre" className='pre-with-border' animation="glow">
                        <Placeholder xs={1}/><br></br>
                        {Array.from({length: 10}).map((_, i) => (
                            <React.Fragment key={i}><Placeholder xs={11}/><br/></React.Fragment>
                        ))}
                        <Placeholder xs={1}/><br></br>
                    </Placeholder>
                </div>
            </>}
            {selectedItem && <>
                {!isReadOnly &&
                    <div className="update-buttons">
                        {selectedItem.itemType === 'file' &&
                            <Button variant="secondary" href={`/files/${selectedItem.id}`} className='mb-2 me-1' target="_blank">
                                download <FaFileDownload/>
                            </Button>
                        }
                        <Button variant="danger" onClick={deleteFileModal.showModal} className='mb-2 me-1'>
                            remove <FaTrash/>
                        </Button>
                        <ConfirmationModal
                            title="Remove item from Data Store"
                            body={<><p>Do you want to remove <strong>{selectedItem.name}</strong> from the Data Store
                                id=<strong>{datastoreId}</strong>?</p>
                            </>}
                            show={deleteFileModal.isShown}
                            onConfirm={() => removeFile(selectedItem)}
                            onClose={deleteFileModal.hideModal}
                        />

                        {selectedItem.itemType === 'file' &&
                            <Button variant="warning" className='mb-2 me-1' onClick={() => onRefreshFileInCache(selectedItem)}>
                                refresh cache <FaSync/>
                            </Button>
                        }
                        <Button variant="primary" className='mb-2 me-1' onClick={synchronizeMetadataModal.showModal}>
                            reload metadata for subtree <FaRetweet/>
                        </Button>
                        <ConfirmationModal
                            title="Reload metadata for subtree"
                            body={<><p>
                                This operation will remove directory "<strong>{selectedItem.name}</strong>"
                                with all its contents and fetch it again from the Data Store.
                            </p><p>Do you wish to continue?</p></>}
                            show={synchronizeMetadataModal.isShown}
                            onConfirm={() => synchronizeMetadataForSubtree(selectedItem)}
                            onClose={synchronizeMetadataModal.hideModal}
                        />
                    </div>
                }
                <div style={{overflow: 'auto'}}>
                    <pre className='pre-with-border'>{JSON.stringify(selectedItem, null, 2)}</pre>
                </div>
            </>}
        </>
    );
}

export default DirectoryTreeFilePanel;