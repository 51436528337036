/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import React, {memo, useEffect, useState} from 'react';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useParams} from "react-router";
import {AppDatastoreApi} from "../../api/AppDatastoreApi";
import {toast} from "react-toastify";
import {datastoresPath, viewDatastorePath} from "../../routes";
import {useLoading} from "../../layouts/Main";
import {EpisodeDirectory, EpisodeMapping} from "../../api/interfaces";
import {EpisodeMappingForm} from "./EpisodeMappingForm";
import {AppEpisodeApi} from "../../api/AppEpisodeApi";

/**
 * Component to edit an episode mapping. Fetches the episode's directories
 * and details, provides a form for editing and updates the episode mapping.
 */
function EditEpisodeMapping() {

    const {datastoreId, episodeId} = useParams()
    const navigate = useNavigate()
    const {setLoading} = useLoading();

    const [episodeDirectories, setEpisodeDirectories] = useState<EpisodeDirectory[]>([]);
    const [selectedEpisodeDirectory, setSelectedEpisodeDirectory] = useState<EpisodeDirectory>({
        path: '',
        dsEntryId: '',
        episodeCode: ''
    } as EpisodeDirectory);
    const [episodeMapping, setEpisodeMapping] = useState<EpisodeMapping>({
        dsId: datastoreId ? datastoreId : '',
        episodeId: '',
    } as EpisodeMapping);


    useEffect(() => {
        if (!datastoreId || !episodeId) {
            toast.error(`${!datastoreId ? 'Datastore' : 'Episode'} ID is undefined`);
            navigate(datastoresPath);
            return;
        }
        const fetchData = async () => {
            setLoading(true);
            try {
                const fetchedEpisodeDirectories = await AppEpisodeApi.getEpisodeDirectories(datastoreId);
                const fetchedEpisodeMapping = await AppEpisodeApi.getEpisodeMapping(episodeId);
                setEpisodeMapping(fetchedEpisodeMapping);

                const episodeDirectory = {
                    path: fetchedEpisodeMapping.episodeDirectoryPath,
                    dsEntryId: fetchedEpisodeMapping.episodeDirectoryDsEntryId,
                    episodeCode: fetchedEpisodeMapping.episodeId
                };
                setSelectedEpisodeDirectory(episodeDirectory)

                if (!fetchedEpisodeDirectories.some(dir => dir.path === episodeDirectory.path)) {
                    setEpisodeDirectories([episodeDirectory, ...fetchedEpisodeDirectories]);
                }
            } catch (error) {
                toast.error(`Failed to fetch data. Reason: ${(error instanceof Error) ? error.message : error}`);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [datastoreId, episodeId]);


    const handleSubmitEdit = async (event: React.FormEvent<HTMLFormElement>, episodeMapping: EpisodeMapping) => {
        event.preventDefault(); // prevent the default form submit behavior which is a page reload
        setLoading(true);
        AppEpisodeApi.updateEpisodeMapping(episodeId as string, episodeMapping)
            .then((response: EpisodeMapping) => {
                toast.success(`Updated an episode mapping with ID: ${response.episodeId}.`);
                navigate(viewDatastorePath(datastoreId as string));
            })
            .catch(error => toast.error(<>Episode mapping <strong>{episodeId}</strong> could not be updated. Reason: {error.message}</>))
            .finally(() => setLoading(false))
    };

    return (
        <Container>
            <Row>
                <Col md={12} className="mb-3">
                    <h2>Update "{episodeId}" episode mapping</h2>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <EpisodeMappingForm
                        episodeDirectories={episodeDirectories}
                        selectedEpisodeDirectory={selectedEpisodeDirectory}
                        setSelectedEpisodeDirectory={setSelectedEpisodeDirectory}
                        episodeMapping={episodeMapping}
                        setEpisodeMapping={setEpisodeMapping}
                        buttonText="Create"
                        onSubmit={handleSubmitEdit}
                    />
                </Col>
                <Col md={6}>
                    <Alert variant="secondary">
                        Fill in the Episode Mapping information and click 'Save'.
                    </Alert>
                    <Alert variant="danger">
                        Caution: changing the episode id will affect links in the insilicolab portal - links to datacenter files that were
                        mapped to this episode id will stop working
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
}

export default memo(EditEpisodeMapping)