/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */

import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import {ConnectionProperties, ConnectionTestResponse, DatastoreProperties} from "../../api/interfaces";
import {useCreateDatastoreConnection} from "./useCreateDatastoreConnection";
import React from "react";

export interface CredentialsInputProps<T> {
    connectionProperties: T;
    contactEmails: string;
    setConnectionProperties: (propertyName: string, value: any) => void;
    setDataStoreProperties: (propertyName: string, value: any) => void;
}

interface CreateConnectionProps<T extends ConnectionProperties> {
    title: string;
    instructions: string[],
    testConnectionApiRequest: (connectionProperties: T) => Promise<ConnectionTestResponse>;
    addConnectionApiRequest: (datastoreProperties: DatastoreProperties<T>) => Promise<{ id: string }>;
    CredentialsInputFormComponent: React.ComponentType<CredentialsInputProps<T>>;
}

/**
 * Component for creating datastore connections (Cibis, CDGP).
 * Utilizes the custom hook {@link useCreateDatastoreConnection} for connection state management.
 * @template T - Type of connection properties
 * @template K - Component type for credential inputs
 * @param {CreateConnectionProps<T>} props - props for the component
 * @returns {JSX.Element} - rendered component
 */
export function AddDatastoreConnection<T extends ConnectionProperties, K extends React.ComponentType<CredentialsInputProps<T>>>(
    props: CreateConnectionProps<T>
) {
    const {
        title,
        instructions,
        testConnectionApiRequest,
        addConnectionApiRequest,
        CredentialsInputFormComponent,
    } = props;

    const {
        datastoreProperties,
        setConnectionProperty,
        setDataStoreProperty,
        handleTestButton,
        handleSaveButton,
        connectionTestResponse,
    } = useCreateDatastoreConnection<T>(
        testConnectionApiRequest,
        addConnectionApiRequest,
    );

    return (
        <Container className="vertical-container">
            <h1>{title}</h1>
            <Row>
                <Col>
                    <CredentialsInputFormComponent
                        connectionProperties={datastoreProperties.connectionProperties}
                        contactEmails={datastoreProperties.contactEmails}
                        setConnectionProperties={setConnectionProperty}
                        setDataStoreProperties={setDataStoreProperty}
                    />

                    <Button variant="primary" onClick={handleTestButton}>
                        Test
                    </Button>

                    <Button variant="success" className='ms-2' disabled={!connectionTestResponse || !connectionTestResponse.success}
                            onClick={handleSaveButton}>
                        Save
                    </Button>
                </Col>
                <Col>
                    <Alert variant="secondary">
                        {instructions.map((instruction, index) => <p key={index}>{instruction}</p>)}
                    </Alert>
                </Col>
            </Row>
            {/* Connection test results */}
            <Row>
                {connectionTestResponse !== null && !connectionTestResponse.success && <Col>
                    <Alert variant="warning">
                        Checking the connection failed, check the connection properties.
                    </Alert>
                    <>
                        <h4>Details:</h4>
                        <pre>{connectionTestResponse.errorMessage}</pre>
                    </>
                </Col>}
            </Row>
            <Row>
                {connectionTestResponse !== null && connectionTestResponse.success && <Col>
                    <Alert variant="success">
                        Connection was successful.
                    </Alert>
                    <h4>Sample metadata from LDAP:</h4>
                    {(typeof connectionTestResponse.testData !== 'string' || connectionTestResponse.testData.length === 0) && <>
                        <Alert variant="warning">
                            No data found! Either the LDAP is empty, the data structure is incorrect (probably
                            missing data with object class <b>'database'</b>), or the <b>base</b> parameter is incorrect.
                        </Alert>
                    </>}
                    <pre>{connectionTestResponse.testData}</pre>
                </Col>}
            </Row> </Container>
    );
}
