/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import React, {useEffect, useState} from 'react';
import Tree from 'rc-tree';
import {Alert, Col, Placeholder, Row} from 'react-bootstrap';
import 'rc-tree/assets/index.css';

import {ConnectionProperties, Datastore, DirectoryTreeNode, EpisodeMapping, EpisodeTreeNode, FileWithMetadata} from "../../api/interfaces";
import {DataNode} from "rc-tree/lib/interface";
import {EventDataNode} from "rc-tree/es/interface";
import {toast} from "react-toastify";
import {AppEpisodeApi} from "../../api/AppEpisodeApi";
import {AppFileMetadataApi} from "../../api/AppFileMetadataApi";
import DirectoryTreeFilePanel from "./DirectoryTreeFilePanel";
import DirectoryTreeEpisodePanel from "./DirectoryTreeEpisodePanel";
import {FaInfoCircle} from "react-icons/fa";

export enum TreeMode {
    Metadata,
    Episodes,
}

interface DataTreeProps<T extends ConnectionProperties> {
    datastoreId: string;
    treeMode: TreeMode;
    isReadOnly: boolean;
    datastore?: Datastore<T>;
}

/**
 * Renders a directory tree of either Metadata or Episodes.
 */
function DirectoryTree<T extends ConnectionProperties>({datastore, datastoreId, treeMode, isReadOnly}: DataTreeProps<T>) {

    const [treeData, setTreeData] = useState<DataNode[]>([]);
    //Default implementation (of loadedKeys mechanism) does not delete loadedKeys of nodes that stopped being present in tree,
    // which prevented reloading children
    const [loadedKeys, setLoadedKeys] = useState<(string | number)[]>([]);
    const [selectedItem, setSelectedItem] = useState<FileWithMetadata>();
    const [selectedEpisode, setSelectedEpisode] = useState<EpisodeMapping>();
    const [showLoadingEpisodePlaceholder, setShowLoadingEpisodePlaceholder] = useState<boolean>(false);
    const [showLoadingDirectoryPlaceholder, setShowLoadingDirectoryPlaceholder] = useState<boolean>(false);
    const [showLoadingFilePlaceholder, setShowLoadingFilePlaceholder] = useState<boolean>(false);
    const [showTreeLoadingPlaceholder, setShowTreeLoadingPlaceholder] = useState<boolean>(false);

    useEffect(() => {
            loadTree();
        }, [datastoreId, datastore]
    );   // Reload tree when datastore changes. E.g. after updating metadata from DatastoresConnectionPreview datastore
    // object is reload and tree is updated

    function loadTree() {
        if (treeMode === TreeMode.Metadata) loadDatastoreDirectories();
        else if (treeMode === TreeMode.Episodes) loadEpisodesDirectories();
        else toast.error(`Unknown tree mode: ${treeMode}`);
    }

    function loadEpisodesDirectories() {
        setShowTreeLoadingPlaceholder(true);
        AppEpisodeApi.listEpisodeDirectory(datastoreId, '1')
            .then(response => {
                // Transform API response to tree structure and set state
                let transformedData = response.map((node: EpisodeTreeNode) => ({
                    title: node.text,
                    key: node.id,
                    isLeaf: node.type === 'file',
                    episodeId: node.episodeId,
                    children: [],
                }));
                setLoadedKeys([]);
                setTreeData(transformedData);
            }).catch((e) => toast.error(<>Error loading episodes directories. Reason: {e.message}</>))
            .finally(() => setShowTreeLoadingPlaceholder(false));
    }

    function loadDatastoreDirectories() {
        setShowTreeLoadingPlaceholder(true);
        AppFileMetadataApi.listDirectory(datastoreId, '1')
            .then(response => {
                // Transform API response to tree structure and set state
                let transformedData = response.map((node: DirectoryTreeNode) => ({
                    title: node.text,
                    key: node.id,
                    isLeaf: node.type === 'file',
                    children: [],
                }));
                setLoadedKeys([]);
                setTreeData(transformedData);
            }).catch((e) => toast.error(<>Error loading datastore directories. Reason: {e.message}</>))
            .finally(() => setShowTreeLoadingPlaceholder(false));
    }

    const updateNodeInChildren = (children: DataNode[], key: string | number, newNode: DataNode): DataNode[] => {
        return children.map(child => {
            if (child.key === key) {
                return newNode;
            } else if (child.children) {
                return {
                    ...child,
                    children: updateNodeInChildren(child.children, key, newNode),
                };
            }
            return child;
        });
    };

    /**
     * Loads data for a node.
     */
    const loadSubtreeForNode = (nodeKey: string | number | undefined): Promise<void> => {
        if (!nodeKey) { // if nodeKey is undefined, load root node (reload the whole tree)
            loadTree();
            return Promise.resolve();
        }
        return AppFileMetadataApi.listDirectory(datastoreId, nodeKey.toString())
            .then(response => {
                let responseChildren = response.map((node: DirectoryTreeNode) => ({
                    title: node.text,
                    key: node.id,
                    isLeaf: node.type === 'file',
                    children: [],
                    selectable: true,
                }));
                /**
                 * Recursively finds and replaces a node in the tree with updated children.
                 *
                 * Searches through the tree structure for the node with the specified key and replaces it with a new
                 * node that includes updated child nodes. The rest of the tree remains unchanged.
                 *
                 * @param {DataNode[]} nodes - The current tree nodes.
                 * @param {string | number} targetKey - The key of the node to replace.
                 * @param {DataNode[]} updatedChildren - The new children to set on the node.
                 * @returns {DataNode[]} - The updated tree with the specified node replaced.
                 */
                function replaceNodeWithUpdatedChildren(nodes: DataNode[],
                                                        targetKey: string | number | undefined = nodeKey,
                                                        updatedChildren: DataNode[] = responseChildren): DataNode[] {
                    return nodes.map((node) => {
                        if (node.key === targetKey) {
                            // Replace the node with the new node having updated children
                            return {
                                ...node,
                                children: updatedChildren,
                            }
                        } else if (node.children) {
                            // Recursively traverse the tree to find the target node and update its children
                            return {
                                ...node,
                                children: replaceNodeWithUpdatedChildren(node.children, targetKey, updatedChildren)
                            }
                        } else {
                            return node;
                        }
                    });
                }

                function findNodeByKey(node: DataNode, targetKey: string | number): DataNode | undefined {
                    if (node.key === targetKey) {
                        return node;
                    } 
                    if (node.children) {
                        for(let child of node.children) {
                            let foundNode = findNodeByKey(child, targetKey);
                            if (foundNode) {
                                return foundNode;
                            }
                        }
                    } 
                    return undefined;
                }

                /**
                 * Recursively collects the keys of all non-leaf nodes in the subtree structure.
                 *
                 * @param node - The current node being processed (root of subtree).
                 * @param keys - An array that will be populated with the keys of non-leaf nodes.
                 */
                function collectDirKeys(node: DataNode, keys: (string | number)[]): void {
                    if (keys === undefined) return;
                    if (!node.isLeaf) keys.push(node.key);
                    if (node.children) {
                        for (let child of node.children) {
                            collectDirKeys(child, keys);
                        }
                    }
                }

                const keysToDelete: (string | number)[] = []; 
                const deleteRootNode = findNodeByKey( {children: treeData} as DataNode, nodeKey);
                if(deleteRootNode) collectDirKeys({children: deleteRootNode.children} as DataNode, keysToDelete);
                setLoadedKeys(oldLoadedKeyes => oldLoadedKeyes.filter(key => !keysToDelete.includes(key)));
                setTreeData(oldTreeData => replaceNodeWithUpdatedChildren(oldTreeData, nodeKey, responseChildren));
            });
    }

    /**
     * Handle tree node selection, fetches data based on the selection.
     */
    const handleTreeNodeSelect = (selectedKeys: (string | number)[], info: any) => {
        let selectedTreeNode: EventDataNode<any> = info.node;
        if (selectedTreeNode.isLeaf) {
            setShowLoadingFilePlaceholder(true)
        } else {
            if (selectedTreeNode.episodeId) setShowLoadingEpisodePlaceholder(true)
            else setShowLoadingDirectoryPlaceholder(true)
        }
        setSelectedItem(undefined);
        setSelectedEpisode(undefined);

        if (selectedTreeNode.episodeId) { // load episode mapping
            AppEpisodeApi.getEpisodeMapping(selectedTreeNode.title)
                .then(setSelectedEpisode)
                .catch((e) => toast.error(<>Error loading episode: <strong>{selectedTreeNode.title}</strong>. Reason: {e.message}</>))
                .finally(() => hideLoadingPlaceholders());
        } else { // load file metadata
            AppFileMetadataApi.getFileWithMetadata(selectedTreeNode.key)
                .then(setSelectedItem)
                .catch((e) => toast.error(<>Error loading item: <strong>{selectedTreeNode.title}</strong>. Reason: {e.message}</>))
                .finally(() => hideLoadingPlaceholders());
        }
    }

    function hideLoadingPlaceholders() {
        setShowLoadingEpisodePlaceholder(false);
        setShowLoadingFilePlaceholder(false);
        setShowLoadingDirectoryPlaceholder(false);
    }

    return (
        <Row className="data-tree">
            <Col md={4} style={{overflow: 'auto'}}>
                {showTreeLoadingPlaceholder ? (
                    <Placeholder as="pre" className='rc-tree-list' animation="glow">
                        {Array.from({length: 10}).map((_, i) => (
                            <React.Fragment key={i}>
                                <Placeholder xs={1} bg="warning" className='ms-2'/> <Placeholder xs={6}/><br/>
                            </React.Fragment>
                        ))}
                    </Placeholder>
                ) : (
                    <>
                        {treeData.length === 0 && (
                            <Alert variant="info" className="d-flex align-items-center">
                                <FaInfoCircle className="me-2"/> No data available for this datastore.
                            </Alert>
                        )}
                        <Tree showLine
                              onSelect={handleTreeNodeSelect}
                              loadData={(node) =>loadSubtreeForNode(node.key)}
                              treeData={treeData}
                              loadedKeys={loadedKeys}
                              onLoad={(_, info) => setLoadedKeys((prev) => !prev.includes(info.node.key) ? [info.node.key, ...prev] : prev)}
                            />
                    </>
                )}
            </Col>
            <Col md={8} style={{paddingLeft: '20px'}}>
                {(selectedEpisode || showLoadingEpisodePlaceholder) &&
                    <DirectoryTreeEpisodePanel selectedEpisode={selectedEpisode}
                                               setSelectedEpisode={setSelectedEpisode}
                                               datastoreId={datastoreId}
                                               loadEpisodesDirectories={loadEpisodesDirectories}
                                               showLoadingEpisodePlaceholder={showLoadingEpisodePlaceholder}
                                               isReadOnly={isReadOnly}/>
                }

                {(selectedItem || showLoadingFilePlaceholder || showLoadingDirectoryPlaceholder) &&
                    <DirectoryTreeFilePanel selectedItem={selectedItem}
                                            datastoreId={datastoreId}
                                            loadSubtreeForNode={loadSubtreeForNode}
                                            setSelectedItem={setSelectedItem}
                                            showLoadingFilePlaceholder={showLoadingFilePlaceholder}
                                            showLoadingDirectoryPlaceholder={showLoadingDirectoryPlaceholder}
                                            isReadOnly={isReadOnly}/>
                }
            </Col>
        </Row>
    );
}

export default DirectoryTree;
