/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {Alert, Col, Container, Row} from "react-bootstrap";

/**
 * Home view component
 */
export default function Home() {

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Alert>
                            Choose module from the top menu
                        </Alert>
                    </Col>
                </Row>
            </Container>
        </>
    )
}