/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {Alert, Button, Container, Table} from "react-bootstrap";
import {AppSeiscompApi} from "../../../api/AppSeiscompApi";
import {useEffect, useState} from "react";
import {SeiscompInfo} from "../../../api/interfaces";
import SeiscompsTableEntry from "./SeiscompsTableEntry";
import {useNavigate} from "react-router";
import {unspecifiedErrorMessage} from "../../../constants";
import {addSeiscompPath} from "../../../routes";
import {useLoading} from "../../../layouts/Main";
import {toast} from "react-toastify";

/**
 * Seiscomps view component
 */
export default function Seiscomps() {
    const navigate = useNavigate()
    const [allSeiscompInfo, setAllSeiscompInfo] = useState<SeiscompInfo[]>([]);
    const [alertMessageList, setAlertMessageList] = useState<string[]>([]);
    const alertVisibilityTime = 5000; // In ms
    const { setLoading } = useLoading();

    useEffect(()=>{
        setLoading(true)
        setAlertMessageList([])
        AppSeiscompApi.getAllSeiscompInfo()
            .then(setAllSeiscompInfo)
            .catch((e) => toast.error(e.message ?? unspecifiedErrorMessage))
            .finally(() => setLoading(false))
    }, [])

    const addTemporaryMessage = (message: string): void => {
        setAlertMessageList((currentAlertMessageList) => [...currentAlertMessageList, message])
        setTimeout(() => removeAlertMessageFromList(message), alertVisibilityTime)
    }

    const removeAlertMessageFromList = (messageToDelete: string): void => {
        setAlertMessageList((currentMessageList) => currentMessageList.filter((messageInList) => messageInList !== messageToDelete));
    }

    const deleteSeiscompTableEntry = (host: string, login: string) => () => setAllSeiscompInfo((previousValue) => previousValue.filter( (seiscompInfo: SeiscompInfo) => seiscompInfo.host !== host || seiscompInfo.login !== login ))

    return <Container>
            {alertMessageList.map((alertMessage: string) => {
                return <Alert 
                    key={alertMessage}
                    variant="warning" 
                    show={alertMessage !== null} 
                    onClose={() => removeAlertMessageFromList(alertMessage)} 
                    dismissible>
                    {alertMessage}
                </Alert>
            })}
            <h2>Registered Seiscomp instances</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Host</th>
                        <th>Login</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {allSeiscompInfo?.map(seiscompInfo => <SeiscompsTableEntry 
                        key={`${seiscompInfo.host}${seiscompInfo.login}`} 
                        seiscompInfo={seiscompInfo} 
                        setLoading={setLoading} 
                        addTemporaryMessage={addTemporaryMessage} 
                        deleteSelf={deleteSeiscompTableEntry(seiscompInfo.host, seiscompInfo.login)}/>)}
                </tbody>
            </Table>
            <Button 
                variant="success" 
                onClick={() => navigate(addSeiscompPath)}>
                Add new seiscomp
            </Button>
        </Container>
}