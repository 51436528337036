/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {CDGPConnectionProperties, DatastoreProperties} from "../../api/interfaces";
import {Form} from "react-bootstrap";
import {memo} from "react";

interface CDGPDataStoreInputProps extends DatastoreProperties<CDGPConnectionProperties> {
    setDataStoreProperties: (propertyName: string, value: any) => void;
    setConnectionProperties: (propertyName: string, value: any) => void;
}

/**
 * Contains form fields for creating CDGP datastore connection.
 *
 * @param {CDGPDataStoreInputProps} props - The properties that define the connection properties and the callback functions
 * that will be called when these properties are changed.
 *
 * @returns A JSX element that represents a form for the connection's properties.
 */
function CDGPConnectionInputForm(props: CDGPDataStoreInputProps) {

    const {setDataStoreProperties, setConnectionProperties, connectionProperties, contactEmails} = props;


    return <>
        <Form>
            <Form.Group className="mb-3" controlId="formServiceUrl">
                <Form.Label>Service URL</Form.Label>
                <Form.Control type="text" placeholder="PROTOCOL://HOST:PORT" required
                              value={connectionProperties.url}
                              onChange={(e) => setConnectionProperties('url', e.target.value)} />
                <Form.Text className="text-muted">
                    URL for service connection, in format http(s)://HOST:PORT
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMetadataListPath">
                <Form.Label>Metadata listing path</Form.Label>
                <Form.Control type="text" placeholder="metadata-list" required
                              value={connectionProperties.metadataListPath}
                              onChange={(e) => setConnectionProperties('metadataListPath', e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMetadataDownloadBaseUrl">
                <Form.Label>URL for downloading metadata files</Form.Label>
                <Form.Control type="text" required
                              value={connectionProperties.metadataDownloadBaseUrl}
                              onChange={(e) => setConnectionProperties('metadataDownloadBaseUrl', e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDownloadFileBaseUrl">
                <Form.Label>URL for downloading data</Form.Label>
                <Form.Control type="text" placeholder="PROTOCOL://URL" required
                              value={connectionProperties.downloadFileBaseUrl}
                              onChange={(e) => setConnectionProperties('downloadFileBaseUrl', e.target.value)} />
                <Form.Text className="text-muted">
                    Base of the url from which we download the data - e.g. https://cdgp.u-strasbg.fr/CDGP-AAAI/comm.php
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formFileDownloadQueryParam">
                <Form.Label>(Optional) data download query parameter</Form.Label>
                <Form.Control type="text" placeholder="resource"
                              value={connectionProperties.fileDownloadQueryParam}
                              onChange={(e) => setConnectionProperties('fileDownloadQueryParam', e.target.value)} />
                <Form.Text className="text-muted">
                    If the data to be downloaded has to be specified as an URL parameter, type here the name of the parameter
                    (e.g. 'resource' for the downolad URL https://cdgp.u-strasbg.fr/CDGP-AAAI/comm.php?resource=SSFS1993-catalogue_Helm)
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formContactEmails">
                <Form.Label>Contact e-mails</Form.Label>
                <Form.Control type="text" placeholder="user@host.com,user2@host.com,insilicolab@cyfronet.pl" required
                              value={contactEmails}
                              onChange={(e) => setDataStoreProperties('contactEmails', e.target.value)}/>
                <Form.Text className="text-muted">
                    List of e-mail addresses to which notifications about CDGP unavailability will be sent
                </Form.Text>
            </Form.Group>
        </Form>
    </>
}

export default memo(CDGPConnectionInputForm)