/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {DirectoryTreeNode, FileWithMetadata, MetadataValidationResult,} from "./interfaces";
import {createAxiosWithDefaultInteceptors} from "./AppApi";

const axiosDefault = createAxiosWithDefaultInteceptors()

/**
 * File metadata api class.
 */
export class AppFileMetadataApi {

    static getFileWithMetadata(fileId: string): Promise<FileWithMetadata> {
        return axiosDefault.get<FileWithMetadata>(`/metadata/file/${fileId}`)
            .then(response => response.data);
    }

    static listDirectory(dsId: string, directoryId: string): Promise<DirectoryTreeNode[]> {
        return axiosDefault.get<DirectoryTreeNode[]>(`/metadata/${dsId}/list`, {
            params: {id: directoryId}
        }).then(response => response.data);
    }

    static deleteItem(itemId: string): Promise<void> {
        return axiosDefault.delete<void>(`/metadata/file/${itemId}`).then(response => response.data);
    }

    static removeDatastoreMetadata(dsId: string): Promise<void> {
        return axiosDefault.delete<void>(`/metadata/${dsId}`).then(response => response.data);
    }

    static synchronizeMetadataIncremental(dsId: string): Promise<void> {
        return axiosDefault.put<void>(`/metadata/${dsId}/synchronize-incremental`).then(response => response.data);
    }

    static synchronizeAllMetadata(dsId: string): Promise<void> {
        return axiosDefault.put<void>(`/metadata/${dsId}/synchronize-all`).then(response => response.data);
    }

    static synchronizeAllMetadataForSubtree(datastoreId: string, subtreeRootId: string): Promise<void> {
        return axiosDefault.put<void>(`/metadata/${datastoreId}/synchronize-all/${subtreeRootId}`).then(response => response.data);
    }

    static refreshFileInCache(fileId: string): Promise<void> {
        return axiosDefault.post<void>(`/files/${fileId}/refresh`).then(response => response.data);
    }

    static verifyMetadata(dsId: string): Promise<MetadataValidationResult> {
        return axiosDefault.get<MetadataValidationResult>(`/metadata/${dsId}/verify`).then(response => response.data);
    }

}