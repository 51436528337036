/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import React, {JSX} from "react";
import {MetadataValidationResult} from "../../api/interfaces";
import {toast} from "react-toastify";
import {AppFileMetadataApi} from "../../api/AppFileMetadataApi";
import {TOAST_ERROR_AUTOCLOSE_TIME, TOAST_SUCCESS_AUTOCLOSE_TIME} from "../../components/useToastActionUtil";

/**
 * Hook to verify metadata consistency.
 */
export const useVerifyMetadata = () => {

    /**
     * Verifies the metadata consistency for a given datastore.
     *
     * @param {string} dataStoreId - The ID of the datastore to verify.
     * @param {Function} [onFinally] - Optional callback to be called once the verification is complete.
     */
    const verifyMetadataConsistency = (dataStoreId: string, onFinally?: any) => {
        const toastId = toast.loading(<>Verifying metadata from Data Store <strong>{dataStoreId}</strong>...</>);
        const toastFinishedLoadingOptions = {
            isLoading: false,
            closeButton: true
        };
        AppFileMetadataApi.verifyMetadata(dataStoreId as string)
            .then((response: MetadataValidationResult) => {
                let metadataInvalid = response.invalidAuxiliaryFiles && response.invalidAuxiliaryFiles.length > 0;
                let validationMessage: JSX.Element;
                if (metadataInvalid) {
                    validationMessage = <>
                        <p>Metadata from Data Store <strong>{dataStoreId}</strong> is invalid:</p>
                        <p>Files <strong>{Array.from(response.invalidAuxiliaryFiles).join(", ")}</strong> should have
                            'auxiliary' set to 1.
                        </p>
                    </>
                    toast.update(toastId, {
                        ...toastFinishedLoadingOptions,
                        autoClose: false,
                        type: toast.TYPE.WARNING,
                        render: validationMessage
                    });
                } else {
                    validationMessage = <>Metadata successfully verified from Data Store <strong>{dataStoreId}</strong>.</>
                    toast.update(toastId, {
                        ...toastFinishedLoadingOptions,
                        autoClose: TOAST_SUCCESS_AUTOCLOSE_TIME,
                        type: toast.TYPE.SUCCESS,
                        render: validationMessage
                    });
                }
            })
            .catch((e) => {
                toast.update(toastId, {
                    ...toastFinishedLoadingOptions,
                    autoClose: TOAST_ERROR_AUTOCLOSE_TIME,
                    type: toast.TYPE.ERROR,
                    render: <>Error while verifying metadata from Data Store <strong>{dataStoreId}</strong>: {e.message}</>
                });
            })
            .finally(() => {
                if (onFinally) onFinally();
            });
    }


    return {
        verifyMetadataConsistency
    };
}