/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {
    CDGPConnectionProperties,
    CibisConnectionProperties,
    ConnectionProperties,
    ConnectionTestResponse,
    Datastore,
    DatastoreInfo,
    DatastoreProperties,
} from "./interfaces";
import {createAxiosWithDefaultInteceptors} from "./AppApi";

/**
 * Datastore axios instance.
 */
const axiosDatastore = createAxiosWithDefaultInteceptors();

/**
 * Datastore's application api class. Contains functions used in Datastore app components.
 */
export class AppDatastoreApi {

    static getAllDatastores(): Promise<DatastoreInfo[]> {
        return axiosDatastore.get<Datastore<ConnectionProperties>[]>(`/data-store`)
            // Map Datastore<ConnectionProperties>[] to DatastoreInfo[]
            .then(response => response.data.map(datastore => ({
                id: datastore.id,
                url: datastore.connectionProperties.url,
                type: datastore.storeType,
            })));
    }

    static removeDatastore(dsId: string): Promise<void> {
        return axiosDatastore.delete<void>(`/data-store/${dsId}`).then(response => response.data);
    }

    static getDatastore<T extends ConnectionProperties>(dataStoreId: string): Promise<Datastore<T>> {
        return axiosDatastore.get<Datastore<T>>(`/data-store/${dataStoreId}`)
            .then(response => response.data);
    }

    static testCibisConnection(cibisConnectionProperties: CibisConnectionProperties): Promise<ConnectionTestResponse> {
        return axiosDatastore.post<ConnectionTestResponse>("/data-store/testCibisConnection", cibisConnectionProperties)
            .then(response => response.data);
    }

    static testCDGPConnection(cdgpConnectionProperties: CDGPConnectionProperties): Promise<ConnectionTestResponse> {
        return axiosDatastore.post<ConnectionTestResponse>("/data-store/testCdgpConnection", cdgpConnectionProperties)
            .then(response => response.data);
    }

    static addCibisConnection(cibisConnectionProperties: DatastoreProperties<CibisConnectionProperties>): Promise<Datastore<CibisConnectionProperties>> {
        return axiosDatastore.put<Datastore<CibisConnectionProperties>>("/data-store/createCibis", cibisConnectionProperties)
            .then(response => response.data);
    }

    static addCDGPConnection(cdgpConnectionProperties: DatastoreProperties<CDGPConnectionProperties>):
        Promise<Datastore<CibisConnectionProperties>> {
        return axiosDatastore.put<Datastore<CibisConnectionProperties>>("/data-store/createCdgp", cdgpConnectionProperties)
            .then(response => response.data);
    }

    static refreshDatastoreConnectionCache(): Promise<void> {
        return axiosDatastore.post<void>('/data-store/refreshConnectionCache').then(response => response.data);
    }

}