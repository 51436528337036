/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
import { SeiscompStation } from "../../../api/interfaces"
import { datacenterDateFormat } from "../../../constants";

interface SeiscompViewNetworkStationsTableEntryProps {
    station : SeiscompStation
}

/**
 * Seiscomps view network station component
 */
export default function SeiscompViewNetworkStationsTableEntry(props: SeiscompViewNetworkStationsTableEntryProps) {
    const {station} = props;
    const arrayDelimiter = ", "
    dayjs.extend(utc)
    
    return <tr>
        <td>{station.episode}</td>
        <td>{station.code}</td>
        <td>{station.description}</td>
        <td>[{station.allowedDownload?.reduce((accumulator, value) => accumulator === "" ? `${value}` : `${accumulator}${arrayDelimiter}${value}`, "")}]</td>
        <td>{station.start ? dayjs.utc(station.start).format(datacenterDateFormat) : ""}</td>
        <td>{station.end ? dayjs.utc(station.end).format(datacenterDateFormat) : ""}</td>
        <td>{station.latitude}, {station.longitude}</td>
        <td>{station.elevation}</td>
    </tr>
}