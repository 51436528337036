/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import { SeiscompNetwork } from "../../../api/interfaces"
import { Card, Table } from "react-bootstrap"
import SeiscompViewNetworkStationsTableEntry from "./SeiscompViewNetworkStationsTableEntry"
import { memo } from "react"

interface SeiscompViewNetworkProps {
    seiscompId : string,
    network : SeiscompNetwork
}

/**
 * Seiscomp view network component
 */
function SeiscompViewNetwork(props: SeiscompViewNetworkProps) {
    const {seiscompId, network} = props;

    return <Card>
        <Card.Header>
            <strong>Code:</strong> {network.code} <strong>Description:</strong> {network.description}
        </Card.Header>
        <Card.Body>
            <Table hover>
                <thead>
                    <tr>
                        <th>Episode</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Download Permission</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Coordinates</th>
                        <th>Elevation</th>
                    </tr>
                </thead>
                <tbody>
                    {network.stations.map(station => <SeiscompViewNetworkStationsTableEntry 
                        key={`${seiscompId}${network.code}${station.code}${station.start}`} 
                        station={station}/>)}
                </tbody>
            </Table>
        </Card.Body>
    </Card>
}

export default memo(SeiscompViewNetwork)