/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {Seiscomp, SeiscompCredentials, SeiscompInfo} from "./interfaces";
import {createAxiosWithResponseBeanHandling} from "./AppApi";

/**
 * Seiscomp axios instance.
 */
const axiosSeiscomp = createAxiosWithResponseBeanHandling();

/**
 * Seiscomp application api class. Should contain functions used in Seiscomp app components.
 */
export class AppSeiscompApi {

    //TODO when refactoring backend (and seiscomp object) adjust endpoint returnig this value
    static getAllSeiscompInfo = async (): Promise<SeiscompInfo[]> => {
        let response = await axiosSeiscomp.get<Seiscomp[]>(`/seiscomp/all`);
        return response.data.map(seiscomp => ({
            host: seiscomp.host,
            login: seiscomp.login
        } as SeiscompInfo));
    }

    static getSeiscomp = async (seiscompInfo: SeiscompInfo): Promise<Seiscomp> => {
        let response = await axiosSeiscomp.get<Seiscomp>(`/seiscomp`, {
            params: {
                host: seiscompInfo.host,
                login: seiscompInfo.login
            }
        });
        return response.data
    }

    static getEpisodesList = async (): Promise<string[]> => {
        let response = await axiosSeiscomp.get<string[]>(`/seiscomp/episodes`)
        return response.data
    }

    static reloadFromSeiscompServer = async (seiscomp: Seiscomp): Promise<Seiscomp> => {
        let response = await axiosSeiscomp.put<Seiscomp>("/seiscomp/reload", seiscomp, {
            headers: {
                "Content-Type": "application/json",
            }
        })
        return response.data;
    }

    static updateSeiscomp = async (seiscomp: Seiscomp): Promise<Seiscomp> => {
        let response = await axiosSeiscomp.post<Seiscomp>("/seiscomp", seiscomp, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                "login": seiscomp.login
            }
        })
        return response.data;
    }

    static deleteSeiscomp = async (host: string, login: string): Promise<void> => {
        let response = await axiosSeiscomp.delete<void>("/seiscomp", {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            params: {
                "login": login,
                "host": host
            }
        })
        return response.data;
    }

    static createSeiscomp = async (credentialsSeiscompData: SeiscompCredentials): Promise<Seiscomp> => {
        let response = await axiosSeiscomp.put<Seiscomp>("/seiscomp", credentialsSeiscompData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }
        })
        return response.data;
    }

    static saveSeiscomp = async (seiscomp: Seiscomp): Promise<Seiscomp> => {
        let response = await axiosSeiscomp.put<Seiscomp>("/seiscomp/save", seiscomp, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        return response.data;
    }
}