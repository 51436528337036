/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */

/**
 * File with routes used in react appplication
 *
 * IMPORTANT:
 * When adding or updating routes in this file, ensure to also reflect these changes in the `ViewMappingController`
 * within the Spring Boot backend. Neglecting to do so may result in some routes not being accessible when
 * the page is reloaded or when trying to access them directly through the browser's URL bar.
 */

// General paths
export const mainPagePath : string = "/"
export const loginPath : string = "/login"
export const createEndpoint : string = "add"
export const updateEndpoint : string = "edit"

// Seiscomp paths
export const seiscompsPath : string = `${mainPagePath}seiscomps`
export const addSeiscompPath : string = `${seiscompsPath}/${createEndpoint}`
export const viewSeiscompPath = (host: string, login: string) : string => `${seiscompsPath}/${host}/${login}`
export const viewSeiscompPathForRouter : string = viewSeiscompPath(":host", ":login")
export const editSeiscompPath = (host: string, login: string) : string => `${seiscompsPath}/${host}/${login}/${updateEndpoint}`
export const editSeiscompPathForRouter : string = editSeiscompPath(":host", ":login")
export const hostOnlySeiscompPath = (host: string) : string => `${seiscompsPath}/${host}`
export const hostOnlySeiscompPathForRouter : string = hostOnlySeiscompPath(":host")

// Datastore paths
export const datastoresPath : string = `${mainPagePath}datastores`
export const viewDatastorePath = (id: string) : string => `${datastoresPath}/${id}`
export const viewDatastorePathForRouter : string = viewDatastorePath(":id")
export const addCibisConnectionPath : string = `${datastoresPath}/add-cibis`
export const addCDGPConnectionPath : string = `${datastoresPath}/add-cdgp`
export const metadataPreviewPath : string = `${datastoresPath}/metadata-preview`

// Episode paths
export const addEpisodePath = (datastoreId: string) : string => `${datastoresPath}/${datastoreId}/add-episode`
export const addEpisodePathForRouter : string = addEpisodePath(":datastoreId")
export const editEpisodePath = (datastoreId: string, episodeId: string): string => `${datastoresPath}/${datastoreId}/edit-episode/${episodeId}`
export const editEpisodePathForRouter : string = editEpisodePath(":datastoreId", ":episodeId")
