/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import React, {useState} from 'react';
import {toast} from "react-toastify";
import {ConnectionProperties, Datastore} from "../../api/interfaces";
import {AppDatastoreApi} from "../../api/AppDatastoreApi";
import {datastoresPath} from "../../routes";
import {useNavigate} from "react-router";
import {useLoading} from "../../layouts/Main";
import {AppFileMetadataApi} from "../../api/AppFileMetadataApi";
import {useToastActionUtil} from "../../components/useToastActionUtil";
import {useVerifyMetadata} from "../DirectoryTree/useVerifyMetadata";

/**
 * Custom React hook to manage and interact with a specific Datastore.
 *
 * This hook abstracts away the API calls related to a Datastore, providing a set of methods
 * and state for managing the datastore's lifecycle. This includes fetching the datastore,
 * removing it, and managing its metadata.
 * @param {string | undefined} id - The ID of the Datastore.
 *
 * @returns {{
 *   datastore: Datastore<T> | undefined,        // State for the current datastore.
 *   loadDatastore: Function,                    // Fetches and sets the datastore.
 *   removeDatastore: Function,                 // Removes the current datastore.
 *   removeAllMetadata: Function,               // Removes all metadata associated with the datastore.
 *   loadNewMetadata: Function,                 // Fetches new or updated metadata.
 *   reloadAllMetadata: Function                // Re-fetches all metadata for the datastore.
 * }}
 */
export const useDatastoreApi = <T extends ConnectionProperties>(id: string | undefined) => {

    const navigate = useNavigate()
    const {setLoading} = useLoading();
    const {verifyMetadataConsistency} = useVerifyMetadata();
    const {handleAxiosResponseWithToast} = useToastActionUtil();

    const [datastore, setDatastore] = useState<Datastore<T>>();

    function loadDatastore() {
        if (id === undefined) {
            toast.error('Datastore ID is undefined');
            navigate(datastoresPath);
            return;
        }
        setLoading(true);
        AppDatastoreApi.getDatastore<T>(id)
            .then(setDatastore)
            .catch((e) => {
                toast.error(`Error loading Data Store with ID: ${id}. Reason: ${e.message}`);
                navigate(datastoresPath);
            })
            .finally(() => setLoading(false))
    }

    const removeDatastore = () => {
        handleAxiosResponseWithToast(
            () => AppDatastoreApi.removeDatastore(id as string),
            <>Deleting Data Store <strong>{id}</strong>...</>,
            () => <>Deleted Data Store <strong>{id}</strong>.</>,
            (e, errMsg) => <>Error deleting Data Store <strong>{id}</strong>: {errMsg}</>,
            () => navigate(datastoresPath),
        );
    }

    /**
     * Removes all metadata associated with the Datastore.
     */
    const removeAllMetadata = () => {
        handleAxiosResponseWithToast(
            () => AppFileMetadataApi.removeDatastoreMetadata(id as string),
            <>Deleting metadata from Data Store <strong>{id}</strong>...</>,
            () => <>Deleted metadata from Data Store <strong>{id}</strong>.</>,
            (e, errMsg) => <>Error deleting metadata from Data Store <strong>{id}</strong>: {errMsg}</>,
            loadDatastore,
        );
    }

    /**
     * Fetches new or updated metadata for the current Datastore.
     * In case of success, it verifies the metadata's consistency.
     */
    const loadNewMetadata = () => {
        handleAxiosResponseWithToast(
            () => AppFileMetadataApi.synchronizeMetadataIncremental(id as string),
            <>Fetching new metadata from Data Store <strong>{id}</strong>...</>,
            () => <>Successfully fetched new metadata from Data Store <strong>{id}</strong>.</>,
            (e, errMsg) => <>Error while fetching new metadata from Data Store <strong>{id}</strong>: {errMsg}</>,
            () => verifyMetadataConsistency(id as string, loadDatastore),
        );
    }

    /**
     * * Re-fetches all the metadata for the current Datastore.
     *  * In case of success, it verifies the metadata's consistency.
     */
    const reloadAllMetadata = () => {
        handleAxiosResponseWithToast(
            () => AppFileMetadataApi.synchronizeAllMetadata(id as string),
            <>Fetching all metadata from Data Store <strong>{id}</strong>...</>,
            () => <>Successfully fetched all metadata from Data Store <strong>{id}</strong>.</>,
            (e, errMsg) => <>Error while fetching metadata from Data Store <strong>{id}</strong>: {errMsg}</>,
            () => verifyMetadataConsistency(id as string, loadDatastore),
        );
    }

    return {
        datastore,
        loadDatastore,
        removeDatastore,
        removeAllMetadata,
        loadNewMetadata,
        reloadAllMetadata
    };
};
