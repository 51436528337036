/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import {AppInfo, LoginLocationState, UserInfo} from "../api/interfaces";
import Header from "../components/Header";
import {Outlet, useLocation, useNavigate, useOutletContext} from "react-router";
import { Spinner } from "react-bootstrap";
import { AppApi } from "../api/AppApi";
import { loginPath } from "../routes";
import { ToastContainer, Slide } from 'react-toastify';

/**
 * Context type that can be used in Outlet element.
 */
type ContextType = { 
    loading: boolean;
    setLoading: (isLoading: boolean) => void;
}

/**
 * Main page view with header, footer, content container and loading view functionality.
 */
export default function Main() {

    const LOGOUT_PARAMETER = "?logout";

    const location = useLocation()
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
    const [appInfo, setAppInfo] = useState<AppInfo | null>(null)
    const [loading, setLoading] = useState<boolean>(false);

    const logout = () => {
        AppApi.logout().then(() => {
            if(location.pathname != loginPath) navigate(loginPath + LOGOUT_PARAMETER, {state: {previousUrl: location.pathname + location.search} as LoginLocationState})
        });
    }

    useEffect(() => {
        AppApi.getUserInfo()
            .then((response: UserInfo) => {
                setUserInfo(response)
            }).catch((e) => {
                if(e?.response?.status === 401) return;
                throw e;
            })
        AppApi.getAppInfo()
            .then((response: AppInfo) => {
                setAppInfo(response)
            }).catch((e) => {
                if(e?.response?.status === 401) return;
                throw e;
            })
    }, [])

    return (
        <>
            {/*ToastContainer options can be live tested here: https://fkhadra.github.io/react-toastify/introduction/*/}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
            {loading && <div className = "loadingComponent">
                <Spinner animation="border"/>
            </div>}
            <Header userName={userInfo?.userName} roles={userInfo?.roles} onLogout={logout}/>
            <Outlet context={ {loading, setLoading} as ContextType }/>
            <Footer userRoles={userInfo?.roles} version={appInfo?.version}/>
        </>
        
    )
}

export function useLoading() {
    return useOutletContext<ContextType>();
}