/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */

/*
* File with constatnts used in react appplication
*/
// ===== Messages =====
export const unspecifiedErrorMessage: string = "Sorry, an error occurred"

// ===== Data formats =====
export const datacenterDateFormat: string = "YYYY-MM-DD HH:mm:ss"