/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import { memo, useState } from "react"
import { LoginData, LoginLocationState } from "../../api/interfaces"
import { Form, Alert, Button, Card } from "react-bootstrap"
import { AppApi } from "../../api/AppApi";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { loginPath, mainPagePath } from "../../routes";

/**
 * Login page component
 */
function Login() {

    const OK_STATUS: number = 200;
    const UNAUTHORIZED_STATUS: number = 401;
    const ERROR_PARAMETER = "?error";

    const navigate = useNavigate();
    const locationState : LoginLocationState = useLocation().state;
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [searchParams] = useSearchParams();

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        AppApi.login({username: username, password: password} as LoginData)
        .then(response => {
            if(response.status === OK_STATUS) {
                navigate(locationState?.previousUrl ?? mainPagePath);
            } 
        }).catch(e => {
            if(e?.response?.status === UNAUTHORIZED_STATUS) {
                navigate(loginPath + ERROR_PARAMETER, {state: locationState});
                return;
            } 
            throw e;
        })
    }

    return <div className="login-wrapper">
                <Card>
                    <Card.Body className="login-card-body">
                        {searchParams.has("error") && <Alert variant="danger">Invalid username and password!</Alert>}
                        {searchParams.has("logout") && <Alert variant="info">You've been logged out successfully.</Alert>}
                        <Form onSubmit={onSubmit}>
                            <Form.Group className="mb-3" controlId="formLogin">
                                <Form.Label>Username:</Form.Label>
                                <Form.Control 
                                    name="username" 
                                    value={username} 
                                    type="text" 
                                    placeholder="Username" 
                                    required
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label>Password:</Form.Label>
                                <Form.Control 
                                    name="password" 
                                    value={password} 
                                    type="password" 
                                    placeholder="Password" 
                                    required          
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            <div className="login-button-wrapper">
                                <Button variant="primary" type="submit">
                                    Login
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
}

export default memo(Login)