/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {JSX, useCallback} from "react";
import {toast} from "react-toastify";
import {AxiosError, isAxiosError} from "axios";


export const TOAST_SUCCESS_AUTOCLOSE_TIME = 4000;
export const TOAST_ERROR_AUTOCLOSE_TIME = 8000;

/**
 * React hook for executing actions with toast feedback.
 * Provides utility to handle promise-based actions using Axios.
 */
export const useToastActionUtil = () => {

    /**
     * Handles an Axios promise action with toast notifications.
     *
     * @template T - The type of data expected in the promise's response.
     * @param {() => Promise<T>} action - The promise-based action to be executed.
     * @param {JSX.Element} loadingMsg - Toast message displayed while the promise is pending.
     * @param {(response: T) => JSX.Element} [successMsg] - Toast message displayed on promise resolution.
     * @param {(error: Error | AxiosError, errorMessage: string) => JSX.Element} [errorMsg] - Toast message displayed on promise rejection.
     * @param {(response: T) => void} [successCallback] - Callback executed on promise resolution.
     * @param {() => void} [finallyCallback] - Callback executed after the promise is either resolved or rejected.
     */
    const handleAxiosResponseWithToast = useCallback(<T = any>(
        action: () => Promise<T>,
        loadingMsg: JSX.Element,
        successMsg?: (response: T) => JSX.Element,
        errorMsg?: (error: Error | AxiosError , errorMessage: string) => JSX.Element,
        successCallback?: (response: T) => void,
        finallyCallback?: () => void
    ) => {

        const toastId = toast.loading(loadingMsg);
        const commonToastOptions = {
            isLoading: false,
            closeButton: true
        };

        action()
            .then((response) => {
                if (successMsg) {
                    toast.update(toastId, {
                        ...commonToastOptions,
                        autoClose: TOAST_SUCCESS_AUTOCLOSE_TIME,
                        type: toast.TYPE.SUCCESS,
                        render: successMsg(response)
                    });
                } else {
                    toast.dismiss(toastId);
                }
                successCallback && successCallback(response);
            })
            .catch((error: Error | AxiosError) => {
                if (errorMsg) {
                    let errorMessage = getErrorMessage(error);
                    toast.update(toastId, {
                        ...commonToastOptions,
                        autoClose: TOAST_ERROR_AUTOCLOSE_TIME,
                        type: toast.TYPE.ERROR,
                        render: errorMsg(error, errorMessage)
                    });
                } else {
                    toast.dismiss(toastId);
                }
            })
            .finally(() => {
                finallyCallback && finallyCallback();
            });
    }, []);

    function getErrorMessage(error: Error | AxiosError<unknown, any>) {
        let errorMessage = error.message;
        if (isAxiosError(error)) {
            if (error.response?.status === 404) errorMessage = 'File not found';
            else errorMessage = error.response?.data?.message || error.message;
        }
        return errorMessage;
    }

    return {
        handleAxiosResponseWithToast,
    }

}