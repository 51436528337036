/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland. 
 * -----------------
 */
import React, {useCallback, useEffect, useState} from "react"
import {Seiscomp} from "../../../api/interfaces"
import {AppSeiscompApi} from "../../../api/AppSeiscompApi"
import {useNavigate, useParams} from "react-router"
import {Alert, Button, Col, Container, Row} from "react-bootstrap"
import SeiscompCredentialsInput from "../SeiscompCredentialsInput"
import {unspecifiedErrorMessage} from "../../../constants"
import {seiscompsPath} from "../../../routes"
import {useLoading} from "../../../layouts/Main"
import SeiscompNetworkInput from "../SeiscompNetworkInput"
import {FaSync} from "react-icons/fa";
import {toast} from "react-toastify";
import {ConfirmationModal, useModalManager} from "../../../components/ConfirmationModal";

/**
 * Seiscomps edit component
 */
export default function SeiscompEdit() {
    const navigate = useNavigate()
    const { host, login } = useParams()
    const [seiscomp, setSeiscomp] = useState<Seiscomp>({id: "", host: "", dcid: "", login: "", password: "", networks: []} as Seiscomp)
    const [episodesList, setEpisodesList] = useState<string[]>([])
    const reloadSeiscompModal = useModalManager()
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const { setLoading } = useLoading();

    useEffect(() => {
        setLoading(true)
        if (host === undefined || login === undefined) {
            navigate(seiscompsPath);
            return;
        }
        let seiscompPromise = AppSeiscompApi.getSeiscomp({host: host, login: login})
            .then(setSeiscomp)

        let episodesListPromise = AppSeiscompApi.getEpisodesList()
            .then(setEpisodesList)

        Promise.all([seiscompPromise, episodesListPromise])
            .catch((e) => {
                toast.error(e.message ?? unspecifiedErrorMessage);
                navigate(seiscompsPath);
            }).finally(() => setLoading(false))
    }, [])

    const setSeiscompProperty = useCallback((propertyName: string, value: any) => {
        setSeiscomp((currentValue) => {
            return {...currentValue, [propertyName]: value } as Seiscomp
        })
    }, [])

    const setSeiscompSpecificStationProperty = useCallback((networkIndex: number, stationIndex: number, satationPropery: string, value: any) => {
        setSeiscomp((currentValue) => {
            const output = {...currentValue, networks: [...currentValue.networks] }
            output.networks[networkIndex] = {...currentValue.networks[networkIndex], stations: [...currentValue.networks[networkIndex].stations]} 
            output.networks[networkIndex].stations[stationIndex] = {...currentValue.networks[networkIndex].stations[stationIndex], [satationPropery]: value}
            return output as Seiscomp
        })
    }, [])

    const handleReloadData = () => {
        reloadSeiscompModal.hideModal()
        if (seiscomp == null) return;
        setLoading(true)
        AppSeiscompApi.reloadFromSeiscompServer(seiscomp)
            .then(setSeiscomp)
            .catch((e) => setErrorMsg(e.message ?? unspecifiedErrorMessage))
            .finally(() => setLoading(false))
    }

    const handleOnClickSaveButton = () => {
        setLoading(true)
        AppSeiscompApi.updateSeiscomp(seiscomp)
            .then(() => {
                toast.success("Seiscomp data successfully updated.")
                navigate(seiscompsPath)
            })
            .catch((e) => setErrorMsg(e.message ?? unspecifiedErrorMessage))
            .finally(() => setLoading(false))
    }

    return <Container className="vertical-container">
        <h1>Edit Seiscomp</h1>
        <Row>
            <Col>
                {errorMsg !== null && <Alert variant="danger">
                    {errorMsg}
                </Alert>}
                <SeiscompCredentialsInput 
                    host={seiscomp.host} 
                    dcid={seiscomp.dcid} 
                    login={seiscomp.login} 
                    password={seiscomp.password} 
                    setSeiscompProperty={setSeiscompProperty} 
                    isEdit/>
            </Col>
            <Col>
                <Button
                    variant="danger" 
                    onClick={reloadSeiscompModal.showModal}>
                    Reload data from Seiscomp server <FaSync/>
                </Button>
                <ConfirmationModal title={`Reload data from Seiscomp server`}
                                   body={<>This operation will reload all the station data from the Seiscomp server.
                                       If there are stations that were removed from the Seiscomp server they will be removed also from
                                       the configuration below. If there are any new stations, they will be added with empty episode and
                                       download permissions. If you have any unsaved changes, they will be lost.<br/><br/>
                                       Do you want to continue?</>}
                                   show={reloadSeiscompModal.isShown}
                                   onConfirm={handleReloadData}
                                   onClose={reloadSeiscompModal.hideModal}/>
            </Col>
        </Row>
        {seiscomp.networks.length > 0 && <>
            <Alert variant="info">
                Assign episode and download permissions if you want a station to be visible for TCS
            </Alert>
            {seiscomp.networks.map((network, index) => <SeiscompNetworkInput 
                key={`${seiscomp.id}${network.code}`} 
                seiscompId={`${seiscomp.id}`} 
                network={network} 
                episodesList={episodesList} 
                setSeiscompSpecificStationProperty = {setSeiscompSpecificStationProperty} 
                indexInNetworkList={index}/>)}
        </>}
        <div className="horizontal-container">
            <Button variant="success" onClick={handleOnClickSaveButton}>
                Save
            </Button>
            <Button variant="warning" onClick={() => navigate(seiscompsPath) }>
                Cancel
            </Button>
        </div>
    </Container>
}