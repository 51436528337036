/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import axios, {AxiosResponse, CreateAxiosDefaults} from "axios";
import {AppInfo, LoginData, LoginLocationState, ResponseBean, UserInfo} from "./interfaces";
import {router} from "../App";
import {loginPath} from "../routes";
import {toast} from "react-toastify";

/** 
 * Function for creating axios instance with default interceptors.
*/
export const createAxiosWithDefaultInteceptors = (options?: CreateAxiosDefaults | undefined) => {
    const newInstance = options ? axios.create(options): axios.create();
 
    newInstance.interceptors.response.use(
       response => response,
       error => {
            if (error?.response?.status === 403) {
                toast.error("Access denied. You cannot perform this action.")
                error.response.data.handled = true;
            } 
            if (error?.response?.status === 401 && window.location.pathname !== loginPath) {
                router.navigate(loginPath, {state: {previousUrl: window.location.pathname + window.location.search } as LoginLocationState});
            }
            return Promise.reject(error);
        }
     );
     return newInstance;
 }

/**
 * Creates an Axios instance tailored for `ResponseBean` structure.
 * - Unwraps `result` on 'SUCCESS'.
 * - Redirects to login on 'UNLOGGED'.
 * - Unwraps error message on 'FAILED'.
 */
export const createAxiosWithResponseBeanHandling = (options?: CreateAxiosDefaults | undefined) => {
    const axiosInstance = createAxiosWithDefaultInteceptors(options);
    axiosInstance.interceptors.response.use(
        (response: AxiosResponse<ResponseBean<any>>) => {
            // Handle DefaultResponse type here
            if (response.data.status) {
                switch (response.data.status) {
                    case 'SUCCESS':
                        response.data = response.data.result;  // unpack result
                        return response;
                    case 'UNLOGGED':
                        if (window.location.pathname !== loginPath) {
                            router.navigate(loginPath,
                                {state: {previousUrl: window.location.pathname + window.location.search} as LoginLocationState});
                        }
                        return Promise.reject(new Error('User is unlogged.'));
                    case 'FAILED':
                        return Promise.reject(new Error(response.data.message));
                    default:
                        return Promise.reject(new Error("Unknown response status: " + response.data.status));
                }
            }
            return Promise.reject(new Error("Default response status not found."));
        },
        error => {
            return Promise.reject(error);
        }
    );
    return axiosInstance;
}

const mainAxiosInstance = createAxiosWithDefaultInteceptors();

/**
 * Main application api class. Should contain functions used in main app component, header and footer.
 */
export class AppApi {

    static getAppInfo = async() => {
        let response = await mainAxiosInstance.get<AppInfo>(`/frontend-api/app-info`);
        return response.data
    }

    static getUserInfo = async () => {
        let response = await mainAxiosInstance.get<UserInfo>(`/frontend-api/user-info`);
        return response.data
    }

    static login = async (loginData: LoginData) => {
        //login is not using default interceptors (they are used for checking for unauthorised response)
        return await axios.post("/login", loginData, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }})
    }
    
    static logout = async () => {
        await mainAxiosInstance.post("/logout")
    }
}