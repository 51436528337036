/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {AppDatastoreApi} from "../../api/AppDatastoreApi";
import {AddDatastoreConnection} from './AddDatastoreConnection';
import CibisCredentialsInputForm from "./CibisCredentialsInputForm";

/**
 * Component for creating a Cibis connection using the {@link AddDatastoreConnection}.
 * It specifies the connection setup instructions, the API request handlers from {@link AppDatastoreApi},
 * and the {@link CibisCredentialsInput} component for inputting credentials.
 *
 * @returns {JSX.Element} The customized CreateDatastoreConnectionComponent for creating a Cibis connection.
 */
export default function AddCibisConnection() {
    return <AddDatastoreConnection
        title="Adding new LDAP connection"
        instructions={[
            '1. Fill in the data required to set up the connection to new LDAP',
            '2. Press "Test" to check the connection',
            '3. Press "Save" to save the connection details',
            '4. Import the data from LDAP',
        ]}
        testConnectionApiRequest={AppDatastoreApi.testCibisConnection}
        addConnectionApiRequest={AppDatastoreApi.addCibisConnection}
        CredentialsInputFormComponent={CibisCredentialsInputForm}
    />;
}