/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {CibisConnectionProperties, DatastoreProperties} from "../../api/interfaces";
import {Form} from "react-bootstrap";
import {memo} from "react";

interface CibisDataStoreInputProps extends DatastoreProperties<CibisConnectionProperties> {
    setDataStoreProperties: (propertyName: string, value: any) => void;
    setConnectionProperties: (propertyName: string, value: any) => void;
}

/**
 * Contains form fields for creating Cibis datastore connection.
 *
 * @param {CibisDataStoreInputProps} props - The properties that define the connection properties and the callback functions
 * that will be called when these properties are changed.
 *
 * @returns A JSX element that represents a form for the connection's properties.
 */
function CibisConnectionInputForm(props: CibisDataStoreInputProps) {

    const {setDataStoreProperties, setConnectionProperties, connectionProperties, contactEmails} = props;


    return <>
        <Form>
            <Form.Group className="mb-3" controlId="formLdapUrl">
                <Form.Label>LDAP URL</Form.Label>
                <Form.Control type="text" placeholder="ldap://HOST:PORT" required
                              value={connectionProperties.url}
                              onChange={(e) => setConnectionProperties('url', e.target.value)}
                />
                <Form.Text className="text-muted">
                    URL for LDAP connection, in format ldap://HOST:PORT
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBase">
                <Form.Label>Base</Form.Label>
                <Form.Control type="text" placeholder="dc=cibis,dc=com" required
                              value={connectionProperties.base}
                              onChange={(e) => setConnectionProperties('base', e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formUserDn">
                <Form.Label>User DN</Form.Label>
                <Form.Control type="text" required
                              value={connectionProperties.userDn}
                              onChange={(e) => setConnectionProperties('userDn', e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" required
                              value={connectionProperties.password}
                              onChange={(e) => setConnectionProperties('password', e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDownloadFileBaseUrl">
                <Form.Label>URL for downloading data</Form.Label>
                <Form.Control type="text" placeholder="PROTOCOL://URL" required
                              value={connectionProperties.downloadFileBaseUrl}
                              onChange={(e) => setConnectionProperties('downloadFileBaseUrl', e.target.value)}
                />
                <Form.Text className="text-muted">
                    Base of the url from which we download the data - e.g. http://cibis.igf.edu.pl.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formUseTls">
                <Form.Check type="checkbox" label="Secure connection"
                            checked={connectionProperties.useTls}
                            onChange={(e) => setConnectionProperties('useTls', e.target.checked)}
                />
                <Form.Text className="text-muted">
                    Check this option if CIBIS supports ldap + starttls connection
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formContactEmails">
                <Form.Label>Contact e-mails</Form.Label>
                <Form.Control type="text" placeholder="user@host.com,user2@host.com,insilicolab@cyfronet.pl" required
                              value={contactEmails}
                              onChange={(e) => setDataStoreProperties('contactEmails', e.target.value)}
                />
                <Form.Text className="text-muted">
                    List of e-mail addresses to which notifications about CIBIS unavailability will be sent
                </Form.Text>
            </Form.Group>
        </Form>
    </>
}

export default memo(CibisConnectionInputForm)