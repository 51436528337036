/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {datastoresPath, mainPagePath, seiscompsPath} from "../routes";

/**
 * Header component parameters
 */
interface HeaderProps {
    userName?: string,
    roles?: string[],

    onLogout(): void
}

/**
 * Page header component
 */
export default function Header({userName, roles, onLogout}: HeaderProps) {

    const isSeiscompAdmin = () => roles?.includes(`SEISCOMP_ADMIN`)
    const isDataStoreAdmin = () => roles?.includes(`DATA_STORE_ADMIN`)
    const isAdmin = () => roles?.includes(`ADMIN`)

    return (
        <>
            <Navbar className="header" bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand as={Link} to={mainPagePath}>EPOS Datacenter</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Nav className="me-auto">
                        {isSeiscompAdmin() && <Nav.Link as={Link} to={seiscompsPath}>Seiscomps</Nav.Link>}
                        {isDataStoreAdmin() && <Nav.Link as={Link} to={datastoresPath}>Data Stores</Nav.Link>}
                    </Nav>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            Signed in as: <strong>{userName}</strong>
                        </Navbar.Text>
                        <Button size="sm" variant="light" className="logout" onClick={() => onLogout()}>Log out</Button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}