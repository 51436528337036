/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import { SeiscompNetwork } from "../../api/interfaces"
import { Card, Table } from "react-bootstrap"
import SeiscompNetworkInputStationsTableEntry from "./SeiscompNetworkInputStationsTableEntry"
import { memo, useCallback } from "react"

interface SeiscompNetworkInputProps {
    seiscompId : string,
    episodesList : string[],
    setSeiscompSpecificStationProperty: (networkIndex: number, stationIndex: number, satationPropery: string, value: any) => void,
    network : SeiscompNetwork,
    indexInNetworkList : number
}

/**
 * Seiscomp input network component
 */
function SeiscompNetworkInput(props: SeiscompNetworkInputProps) {
    const {seiscompId, episodesList, setSeiscompSpecificStationProperty, network, indexInNetworkList} = props;

    const setSpecificStationProperty = useCallback((stationIndex: number, satationPropery: string, value: any) => {
        setSeiscompSpecificStationProperty(indexInNetworkList, stationIndex, satationPropery, value);
    }, [])

    return <Card>
        <Card.Header>
            <strong>Code:</strong> {network.code} <strong>Description:</strong> {network.description}
        </Card.Header>
        <Card.Body>
            <Table hover>
                <thead>
                    <tr>
                        <th>Episode</th>
                        <th>Download Permission</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Coordinates</th>
                        <th>Elevation</th>
                    </tr>
                </thead>
                <tbody>
                    {network.stations.map((station, index) => <SeiscompNetworkInputStationsTableEntry 
                        key={`${seiscompId}${network.code}${station.code}${station.start}`} 
                        station={station} 
                        episodesList={episodesList} 
                        setSpecificStationProperty={setSpecificStationProperty} 
                        indexInStationList={index}/>)}
                </tbody>
            </Table>
        </Card.Body>
    </Card>

}

export default memo(SeiscompNetworkInput)