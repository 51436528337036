/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {Container, Row} from "react-bootstrap";

/**
 * Footer component parameters
 */
interface FooterProps {
    userRoles?: string[]
    version?: string
}

/**
 * Page footer component.
 */
export default function Footer({userRoles, version}: FooterProps) {

    return (
        <>
            <footer className="footer mt-auto py-3 bg-light">
                <Container>
                    <Row className="justify-content-between">
                        <p className="col-md-4 mb-0 text-muted">© {new Date().getFullYear()} Cyfronet v{version}</p>
                        <div className="nav col-md-4 justify-content-end text-muted">
                            [{userRoles?.map((value, i) => [i > 0 && ", ", value])}]
                        </div>
                    </Row>
                </Container>
            </footer>
        </>
    )
}