/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */
import {useCallback, useEffect, useState} from "react"
import {Seiscomp, SeiscompCredentials} from "../../../api/interfaces"
import {AppSeiscompApi} from "../../../api/AppSeiscompApi"
import {useNavigate} from "react-router"
import {Alert, Button, Col, Container, Row} from "react-bootstrap"
import SeiscompCredentialsInput from "../SeiscompCredentialsInput"
import {unspecifiedErrorMessage} from "../../../constants"
import {seiscompsPath} from "../../../routes"
import {useLoading} from "../../../layouts/Main"
import SeiscompNetworkInput from "../SeiscompNetworkInput"
import {toast} from "react-toastify";

/**
 * Seiscomp create component
 */
export default function SeiscompCreate() {
    const navigate = useNavigate()
    const [seiscomp, setSeiscomp] = useState<Seiscomp>({id: "", host: "", dcid: "", login: "", password: "", networks: []} as Seiscomp);
    const [episodesList, setEpisodesList] = useState<string[]>([])
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const {setLoading} = useLoading();

    useEffect(() => {
        setLoading(true)
        AppSeiscompApi.getEpisodesList()
            .then(setEpisodesList)
            .catch((e) => {
                toast.error(e.message ?? unspecifiedErrorMessage);
                navigate(seiscompsPath);
            }).finally(() => {
                setLoading(false)
            })

    }, [])

    const setSeiscompProperty = useCallback((propertyName: string, value: any) => {
        setSeiscomp((currentValue) => {
            return {...currentValue, [propertyName]: value} as Seiscomp
        })
    }, [])

    const setSeiscompSpecificStationProperty = useCallback((networkIndex: number, stationIndex: number, satationPropery: string, value: any) => {
        setSeiscomp((currentValue) => {
            const output = {...currentValue, networks: [...currentValue.networks]}
            output.networks[networkIndex] = {
                ...currentValue.networks[networkIndex],
                stations: [...currentValue.networks[networkIndex].stations]
            }
            output.networks[networkIndex].stations[stationIndex] = {
                ...currentValue.networks[networkIndex].stations[stationIndex],
                [satationPropery]: value
            }
            return output as Seiscomp
        })
    }, [])

    const handleSaveButton = () => {
        setErrorMsg(null)
        setLoading(true);
        AppSeiscompApi.saveSeiscomp(seiscomp)
            .then(() => {
                toast.success("Seiscomp successfully added.")
                navigate(seiscompsPath)
            })
            .catch((e) => setErrorMsg(e.message ?? unspecifiedErrorMessage))
            .finally(() => setLoading(false))
    }


    const handleConnectButton = () => {
        setErrorMsg(null)
        setLoading(true)
        AppSeiscompApi.createSeiscomp({
            host: seiscomp.host,
            dcid: seiscomp.dcid,
            login: seiscomp.login,
            password: seiscomp.password
        } as SeiscompCredentials)
            .then((receivedSeiscomp) => {
                // Set the received seiscomp but without the password. Server does not return the password for security
                // reasons.
                setSeiscomp(prevState => ({
                    ...prevState,
                    ...receivedSeiscomp,
                    password: prevState.password
                }));
            })
            .catch((e) => setErrorMsg(e.message ?? unspecifiedErrorMessage))
            .finally(() => setLoading(false))
    }

    return <Container className="vertical-container">
        <h1>Create Seiscomp</h1>
        <Row>
            <Col>
                {errorMsg !== null && <Alert variant="danger">
                    {errorMsg}
                </Alert>}
                <SeiscompCredentialsInput
                    host={seiscomp.host}
                    dcid={seiscomp.dcid}
                    login={seiscomp.login}
                    password={seiscomp.password}
                    setSeiscompProperty={setSeiscompProperty}
                    isEdit={false}/>
            </Col>
            <Col>
                <Alert variant="secondary">
                    <p>Fill in the Seiscomp connection information and click 'Connect'.</p>
                    <p>In the next step, you will choose stations available from TCS and assign episodes to them. </p>
                </Alert>
            </Col>
        </Row>
        <div className="horizontal-container">
            <Button
                variant="success"
                onClick={handleConnectButton}>
                Connect
            </Button>
        </div>
        {seiscomp.networks.length > 0 && <>
            <Alert variant="info">
                Assign episode and download permissions if you want a station to be visible for TCS
            </Alert>
            {seiscomp.networks.map((network, index) => <SeiscompNetworkInput
                key={`${seiscomp.id}${network.code}`}
                seiscompId={`${seiscomp.id}`}
                network={network}
                episodesList={episodesList}
                setSeiscompSpecificStationProperty={setSeiscompSpecificStationProperty}
                indexInNetworkList={index}/>)}
            <div className="horizontal-container">
                <Button variant="success" onClick={handleSaveButton}>
                    Save
                </Button>
                <Button variant="warning" onClick={() => navigate(seiscompsPath)}>
                    Cancel
                </Button>
            </div>
        </>}
    </Container>
}